import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ServiceDropdown({setShowDropdownNavbarMenu}) {
  const navigate = useNavigate();
  const onClickHandler = (path)=>{
    navigate(path);
    setShowDropdownNavbarMenu(false);
  }


  return (
    <div>
      <div
      onClick={()=> onClickHandler("/services/9")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Smart Cards
      </div>

      <div
      onClick={()=> onClickHandler("/services/10")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        IT Hardware
      </div>

      <div
        onClick={()=> onClickHandler("/services/11")}
        className="block text-left border-t py-3 border-red-200  text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Customised Electronic Devices
      </div>

      <div
        onClick={()=> onClickHandler("/services/4")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        UI/UX Design
      </div>

      <div
        onClick={()=> onClickHandler("/services/2")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        iOS App Development
      </div>

      <div
        onClick={()=> onClickHandler("/services/2")}
        className="block text-left border-t py-3 border-red-200  text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Android App Development
      </div>

      <div
        onClick={()=> onClickHandler("/services/1")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Website & Web App Development
      </div>

      <div
        onClick={()=> onClickHandler("/services/1")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        ERP Service
      </div>

      <div
        onClick={()=> onClickHandler("/services/1")}
        className="block text-left border-t py-3 border-red-200  text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        SaaS
      </div>

      <div
        onClick={()=> onClickHandler("/services/8")}
        className="block text-left border-t py-3 border-red-200  text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Cloud Services
      </div>

      <div
        onClick={()=> onClickHandler("/services/5")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Scalable System Design
      </div>

      <div
        onClick={()=> onClickHandler("/services/6")}
        className="block text-left border-t py-3 border-red-200 text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        Maintenance And Support
      </div>

      <div
        onClick={()=> onClickHandler("/services/7")}
        className="block text-left border-t py-3 border-red-200  text-base font-normal leading-[19.09px] hover:bg-green-100 px-2 cursor-pointer"
      >
        QA Testing & Automation
      </div>
    </div>
  );
}

export default ServiceDropdown;
