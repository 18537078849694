/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

function PowerfulStrategiesItem() {
  const [readMoreStatus, setReadMoreStatus] = useState(true);

  let content;

  if (readMoreStatus) {
    content = 'Adhering to rigorous quality management procedures and meeting global security...'
  } else {
    content = 'Adhering to rigorous quality management procedures and meeting global security standards, we offer IT outsourcing services featuring world-class ICMS to cater to your needs.'
  }

  return (

    <div className="flex gap-3">
      <div className="whyChooseSmartmatrixListItemNumber">03.</div>
      <div>
        <div className="sectionListTitle">Powerful Strategies</div>
        <div className="sectionListListContent">
          {content}
          {readMoreStatus && <span className="text-[#195CA5] font-semibold cursor-pointer" onClick={() => setReadMoreStatus(false)}>Read More</span>}
          {!readMoreStatus && <span className="text-[#195CA5] font-semibold cursor-pointer" onClick={() => setReadMoreStatus(true)}>Read Less</span>}
        </div>
      </div>
    </div>
  );
}

export default PowerfulStrategiesItem;
