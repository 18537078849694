import React from "react";
import "./ClientAndConcernCard.css";

function ClientAndConcernCard({ cardDetails }) {
  return (
    <div>
      <div className="imageContainer">
        <img
          src={cardDetails.image}
          alt={cardDetails.id}
          className="object-scale-down"
        />
      </div>
    </div>
  );
}

export default ClientAndConcernCard;
