import React from 'react';
import './HeroFooterSectionCard.css';

function HeroFooterSectionCard({ cardDetails }) {
  return (
    <div className="p-[30px] bg-white heroFooterSectionCardContainer">
      <div className="flex items-center gap-2">
        <div className="bg-[#195CA5] p-[13px] inline-block">
          <img src={cardDetails.imgae} alt={cardDetails.imgae} className="h-[36.67px]" />
        </div>
        <div className="heroFooterSectionCardTitle">{cardDetails.title || 'No Title Found'}</div>
      </div>

      <div className="heroFooterSectionCardContent mt-[18px]">
        {cardDetails.content || 'No Content Found'}
      </div>
    </div>
  );
}

export default HeroFooterSectionCard;
