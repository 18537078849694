/* eslint-disable max-len */
import React from "react";
import ECitySolutionCardBackground from "../../../assets/Components/Ecity/ECitySolution/ECitySolutionCardBackground.png";
import consultancy from "../../../assets/Components/Ecity/ECitySolution/consultancy.png";
import coordination from "../../../assets/Components/Ecity/ECitySolution/coordination.png";
import graphicdesign from "../../../assets/Components/Ecity/ECitySolution/graphic-design.png";
import implementation from "../../../assets/Components/Ecity/ECitySolution/implementation.png";
import leftImage from "../../../assets/Components/Ecity/ECitySolution/leftImage.png";
import maintenance from "../../../assets/Components/Ecity/ECitySolution/maintenance.png";
import { eCitySolutionCardData } from "../../../store/eCityStore";
import MiniReUseableTitle from "../../Home/MiniReUseableTitle/MiniReUseableTitle";
import "./ECitySolution.css";
import ECitySolutionCard from "./ECitySolutionCard";
import "./ECitySolutionCard.css";
import { useNavigate } from "react-router-dom";

function ECitySolution() {
  const navigate = useNavigate();
  return (
    <div className="eCitySolution-hero-background-image pb-[45px] md:pb-[90px]">
      <div className="container relative">
        <div>
          <div className="relative top-[-100px]">
            <div className="absolute  w-full">
              <div className="relative w-full">
                <div className="w-full">
                  <img
                    src={ECitySolutionCardBackground}
                    alt=""
                    className="w-full h-[229px] object-fill"
                  />
                </div>
                <div className="grid grid-flow-row grid-cols-5 absolute top-0 w-full">
                  <div className="flex justify-between">
                    <div className="flex justify-center h-full w-full">
                      <div className="flex flex-col justify-center items-center h-[229px]">
                        <div>
                          <img
                            src={consultancy}
                            alt=""
                            className="eCitySolutionCardImage"
                          />
                        </div>
                        <div className="eCitySolutionCardTitle">
                          Consultancy
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center h-full">
                      <div className="w-[2px] max-h-[190px] bg-[#D4D4D4] h-full" />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex justify-center h-full w-full">
                      <div className="flex flex-col justify-center items-center h-[229px]">
                        <div>
                          <img
                            src={coordination}
                            alt=""
                            className="eCitySolutionCardImage"
                          />
                        </div>
                        <div className="eCitySolutionCardTitle">
                          Project Coordination
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center h-full">
                      <div className="w-[2px] max-h-[190px] bg-[#D4D4D4] h-full" />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex justify-center h-full w-full">
                      <div className="flex flex-col justify-center items-center h-[229px]">
                        <div>
                          <img
                            src={graphicdesign}
                            alt=""
                            className="eCitySolutionCardImage"
                          />
                        </div>
                        <div className="eCitySolutionCardTitle">
                          Design & Development
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center h-full">
                      <div className="w-[2px] max-h-[190px] bg-[#D4D4D4] h-full" />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex justify-center h-full w-full">
                      <div className="flex flex-col justify-center items-center h-[229px]">
                        <div>
                          <img
                            src={implementation}
                            alt=""
                            className="eCitySolutionCardImage"
                          />
                        </div>
                        <div className="eCitySolutionCardTitle">
                          Implementation
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center h-full">
                      <div className="w-[2px] max-h-[190px] bg-[#D4D4D4] h-full" />
                    </div>
                  </div>

                  <div className="flex flex-col justify-center items-center h-[229px]">
                    <div>
                      <img
                        src={maintenance}
                        alt=""
                        className="eCitySolutionCardImage"
                      />
                    </div>
                    <div className="eCitySolutionCardTitle">Support</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div />

        <div className="container grid grid-flow-row lg:grid-cols-2 gap-5 relative pt-[200px]">
          <div className="order-last lg:order-first mt-[25px] ">
            <MiniReUseableTitle
              title="Welcome to E-City Solution"
              color="#195CA5"
            />

            <div className="sectionTitle aboutOurCompanyRightSectionMargin">
              Unleash Tomorrow, Connect Today.
            </div>

            <div className="sectionContent aboutOurCompanyRightSectionMargin">
              SmartMatrix is presently engaged in diverse E-City solutions,
              aligning seamlessly with our strategy for a strong international
              presence. With a commitment to innovation, we shape urban
              environments, optimizing resources and creating intelligent
              solutions that redefine the way cities function and thrive.
            </div>

            {eCitySolutionCardData.map((cardDetails) => (
              <ECitySolutionCard
                key={cardDetails.id}
                cardDetails={cardDetails}
              />
            ))}

            <div>
              <button
                type="button"
                className="DiscoverMoreButton inline-block"
                onClick={() => navigate("/contact")}
              >
                GET SOLUTIONS
              </button>
            </div>
          </div>

          <div className="h-full w-full flex justify-center items-center">
            <div>
              <img src={leftImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ECitySolution;
