/* eslint-disable max-len */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ServiceDropdownlogo from '../../../../assets/Navbar/ServiceDropdown/ServiceDropdownlogo.png';
import './ServiceDropdown.css';

function ServiceDropdown({setServiceDropdownStatus}) {
  const navigate = useNavigate();
  const onClickHandler = (path)=>{
    navigate(path);
    setServiceDropdownStatus(false);
  }


  return (
    <div className="serviceDropdownContainer">
      <div className="flex gap-[15px]">
        <img src={ServiceDropdownlogo} alt="" className="h-[25px]" />
        <div>
          <div className="serviceDropdownTitle">We Trade Solutions.</div>
          <div className="serviceDropdownContent">Driving Technological Evolution: We Trade Solutions, Delivering Innovative Technology and Comprehensive IT Services Globally.</div>
        </div>
      </div>
      <div className="bg-[#D4D4D4] w-full h-[1px] my-5" />

      <div className="flex gap-[60px]">
        <div>
          <div className="serviceDropdownTitle mb-3">
          
            Services
            <span className="text-[#2792D0]">.</span>
            
          </div>

          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/9")}>Smart Cards</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/10")}>IT Hardware</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/11")}>Customised Electronic Devices</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/4")}>UI/UX Design</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/2")}>iOS App Development</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/2")}>Android App Development</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/1")}>Website & Web App Development</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/1")}>ERP Service</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/1")}>SaaS</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/8")}>Cloud Services</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/5")}>Scalable System Design</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/6")}>Maintenance And Support</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/7")}>QA Testing & Automation</div></div>

        </div>

        <div>
          <div className="serviceDropdownTitle mb-3">
          
            Solutions
            <span className="text-[#2792D0]">.</span>
            
          </div>

          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/9")}>SmartCard Solutions</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/14")}>Education Solutions</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/13")}>Home Solutions</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/13")}>Industrial Solutions</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/15")}>Healthcare Solutions</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/12")}>RFID/Barcode Solutions</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/3")}>E-Commerce Solutions</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/16")}>Commodity Smart Solutions</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/8")}>Web & Cloud Solutions</div></div>
          <div className="flex"><div className="serviceDropdownContent serviceDropdownLink cursor-pointer" onClick={()=> onClickHandler("/services/2")}>IOS/Android App Soltuions</div></div>
        </div>
      </div>
    </div>
  );
}

export default ServiceDropdown;
