/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

function UniqueSolutionsItem() {
  const [readMoreStatus, setReadMoreStatus] = useState(true);

  let content;

  if (readMoreStatus) {
    content = 'SmartMatrix delivers a distinctive software development solution by blending...'
  } else {
    content = 'SmartMatrix delivers a distinctive software development solution by blending innovative technologies, customizing strategies to client needs, and prioritizing efficiency. Our unique approach ensures tailored, cutting-edge solutions that set SmartMatrix apart in the dynamic landscape of software development.'
  }

  return (

    <div className="flex gap-3">
      <div className="whyChooseSmartmatrixListItemNumber">02.</div>
      <div>
        <div className="sectionListTitle">Unique Solutions</div>
        <div className="sectionListListContent">
          {content}
          {readMoreStatus && <span className="text-[#195CA5] font-semibold cursor-pointer" onClick={() => setReadMoreStatus(false)}>Read More</span>}
          {!readMoreStatus && <span className="text-[#195CA5] font-semibold cursor-pointer" onClick={() => setReadMoreStatus(true)}>Read Less</span>}
        </div>
      </div>
    </div>
  );
}

export default UniqueSolutionsItem;
