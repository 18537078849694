/* eslint-disable max-len */
import React from 'react';
import './AboutOurCompanyCard.css';

function AboutOurCompanyCard({ cardDetails }) {
  return (
    <div>
      <div className="flex my-[30px] gap-5">
        <div>
          <div className="h-[60px] w-[60px]"><img src={cardDetails.imgae} alt={cardDetails.imgae} className="object-cover" /></div>
        </div>
        <div>
          <div className="aboutOurCompanyCardTitle">{cardDetails.title || 'No Title Found'}</div>
          <div className="aboutOurCompanyCardContent">
            {cardDetails.content || 'No Content Found'}
          </div>
        </div>
      </div>
      { (cardDetails.id !== 2) && <hr />}
    </div>
  );
}

export default AboutOurCompanyCard;
