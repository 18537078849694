/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './RecentlyCompletedWorkCard.css';

function RecentlyCompletedWorkCard({ cardDetails }) {
  const [isHoverd, setIsHoverd] = useState(false);

  const handleMouseOver = () => {
    setIsHoverd(true);
  };

  // Event handler for Mouse Over Left
  const handleMouseLeave = () => {
    setIsHoverd(false);
  };

  return (
    <div
      className="relative overflow-hidden"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <img src={cardDetails.image} alt="" />
      </div>

      <div
        className={`absolute w-full p-5 bottom-0 ${
          isHoverd ? 'bg-[#2792D0E5] h-full' : ''
        }`}
      >
        <div className="flex flex-col items-center w-full">
          <div className="recentlyCompletedWorkCardTitle text-center">
            {cardDetails.title}
          </div>
          <div className="recentlyCompletedWorkCardCetagories text-center mt-[5px]">
            {cardDetails.cetagories}
          </div>
        </div>

        <div className={`mt-[30px] ${isHoverd ? 'block' : 'hidden'}`}>
          {cardDetails.list.map((content, index) => (
            <div
              className="flex gap-2 recentlyCompletedWorkCardListItem"
              key={index}
            >
              <div>
                <FontAwesomeIcon
                  icon={faCircleChevronRight}
                  size="xl"
                  style={{ color: '#ffffff' }}
                />
              </div>
              <div className="recentlyCompletedWorkCardListItemContent">
                {content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RecentlyCompletedWorkCard;
