/* eslint-disable react/no-unescaped-entities */
import React from "react";
import ecity from "../../../assets/Components/Ecity/Hero/ecity.png";
import ecityLogo from "../../../assets/Navbar/ecitylogo.svg";
import "./Hero.css";

function Hero() {
  return (
    <div className="eCitybackgroundImage">
      <div className="container h-full">
        <div className="flex flex-col gap-5 justify-center items-center h-full">
          <div className="bg-white/10 inline-block">
            <div className="flex items-center gap-2 mx-[30px] my-[15px]">
              <div>
                <img src={ecityLogo} alt="" className="w-[20px]" />
              </div>

              <div className="heroTitle1">SOLUTION FOR E-CITY</div>
            </div>
          </div>

          <div>
            <div className="heroTitle2  text-center">
              Igniting Tomorrow's Urban Brilliance.
            </div>
            <div className="heroContent text-center mt-5">
              we pioneer E-City solutions, seamlessly integrating technology
              into urban landscapes.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
