/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { technologyThornWeUseData } from '../../../store/store';
import MiniReUseableTitle from '../MiniReUseableTitle/MiniReUseableTitle';
import './TechnologyThornWeUse.css';
import TechnologyThornWeUseButton from './TechnologyThornWeUseButton';
import TechnologyThornWeUseCard from './TechnologyThornWeUseDetails/TechnologyThornWeUseCard';

function TechnologyThornWeUse() {
  const [selectedTechnologyThornWeUseButton, setSelectedTechnologyThornWeUseButton] = useState('FRONTEND');
  //   console.log('selectedTechnologyThornWeUseButton = ', selectedTechnologyThornWeUseButton);

  // What to Render

  let content = null;

  if (selectedTechnologyThornWeUseButton !== '') {
    let selectedTechnologyData;
    selectedTechnologyData = technologyThornWeUseData.find((item) => item.title === selectedTechnologyThornWeUseButton);
    // console.log('selectedTechnologyData = ', selectedTechnologyData);
    if (selectedTechnologyData.details.length === 0) {
      // console.log('No Data Found');
      content = <div>content</div>
    }
    if (selectedTechnologyData.details.length > 0) {
      // console.log('Data Found');

      content = (
        <div className="grid grid-flow-row lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 mt-[60px]">
          {selectedTechnologyData.details.map((cardDetails, index) => <TechnologyThornWeUseCard cardDetails={cardDetails} key={index} />)}
        </div>
      )
    }
  }

  return (
    <div className="sectionContentMargin1 container">
      <div className="flex justify-center">
        <div className="flex flex-col items-center justify-center max-w-[405px]">

          <MiniReUseableTitle title="Technology Thorn We Use" color="#195CA5" />

          <div className="sectionTitle technologyThornWeUseContentMargin text-center">We Hold Expertise In The Following Platforms</div>
        </div>
      </div>

      <div className="flex flex-wrap justify-center gap-7 mt-8 md:mt-[40px] lg:mt-[60px]">
        {technologyThornWeUseData.map((buttonDetails) => (
          <TechnologyThornWeUseButton
            key={buttonDetails.id}
            buttonDetails={buttonDetails}
            selectedTechnologyThornWeUseButton={selectedTechnologyThornWeUseButton}
            setSelectedTechnologyThornWeUseButton={setSelectedTechnologyThornWeUseButton}
          />
        ))}

      </div>

      {/* {selectedTechnologyThornWeUseButton !== '' && (technologyThornWeUseData.selectedTechnologyThornWeUseButton.length === 0) && (
      <div className="mt-[60px]">
        No Data Found
      </div>
      )} */}

      {content}

    </div>
  );
}

export default TechnologyThornWeUse;
