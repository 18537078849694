/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from "react";
// import { heroFooterSectionCardData } from '../../store/store';
import "./Hero.css";
// import HeroFooterSectionCard from './HeroFooterSectionCard/HeroFooterSectionCard';
import { useNavigate } from "react-router-dom";

function Hero() {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="hero-background-image">
        <div className="container">
          <div className="max-w-[511px] flex flex-col gap-[25px] pt-[130px]">
            <div className="bg-white/10 inline-block max-w-[400px]">
              <div className="flex items-center mx-[30px] my-[15px]">
                <div className="bg-[#2792D0] h-[2px] w-[36px] mr-[10px]" />

                <div className="heroTitle1">SOLUTION FOR YOUR BUSINESSES</div>
              </div>
            </div>

            <div className="heroTitle2">
              Providing The Best Services & IT Solutions
            </div>
            <div className="heroContent">
              Global IT & industrial brands' exclusive partner, providing
              reliable support and consultancy in Asia and Europe.
            </div>

            {/* <button type="button" className="navberGetSolutionButton inline-block">DISCOVER MORE</button> */}
            {/* <div className="navberGetSolutionButton inline-block">DISCOVER MORE</div> */}
            <div>
              <button
                type="button"
                className="DiscoverMoreButton inline-block"
                onClick={() => navigate("/services/1")}
              >
                DISCOVER MORE
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container relative top-[-80px] ">
        <div className="grid grid-flow-row lg:grid-cols-3 md:grid-cols-2 gap-5">
          {heroFooterSectionCardData.map((cardDeatails) => <HeroFooterSectionCard key={cardDeatails.id} cardDeatails={cardDeatails} />)}
        </div>
      </div> */}

      {/* <div className="container relative top-[-80px]">
        <div className="flex justify-between gap-2">
          <HeroFooterSectionCard />
          <HeroFooterSectionCard />
          <HeroFooterSectionCard />
        </div>
      </div> */}

      {/* <div className="absolute bottom-[-100px] flex justify-between gap-2 ">
        <HeroFooterSectionCard />
        <HeroFooterSectionCard />
        <HeroFooterSectionCard />
      </div> */}
    </div>
  );
}

export default Hero;
