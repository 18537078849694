/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import eCityLogo from "../../../assets/Navbar/ecitylogo.svg";
import logo from "../../../assets/Navbar/logo.png";
import "./BigScreenNavbar.css";
import ServiceDropdown from "./ServiceDropdown/ServiceDropdown";

function BigScreenNavbar() {
  const [serviceDropdownStatus, setServiceDropdownStatus] = useState(false);

  const serviceDropdownHandler = () => {
    setServiceDropdownStatus(!serviceDropdownStatus);
  };

  return (
    <div className="hidden px-[30px] py-[25px] lg:flex justify-between items-center">
      <Link to="/">
        <img src={logo} alt="" className="h-[48px]" />
      </Link>

      <div className="flex items-center gap-5">
        <div className="relative">
          <div
            className="navberLinkContent flex items-center"
            onClick={() => serviceDropdownHandler()}
          >
            Services
            <div className="ml-[5px]">
              <FontAwesomeIcon icon={faChevronDown} size="sm" />
            </div>
          </div>

          <div className="absolute top-[68px] z-10">
            {serviceDropdownStatus && <ServiceDropdown setServiceDropdownStatus={setServiceDropdownStatus} />}
          </div>
        </div>

        <Link to="/client">
          <div className="navberLinkContent">Our Clients</div>
        </Link>
        <div className="navberLinkContent">
          <Link to="/about">About Us</Link>
        </div>
        <div className="navberLinkContent">
          <Link to="/career">Career</Link>
        </div>
        <Link to="/ecity">
          <div className="navberLinkContent flex items-center">
            <div>
              <img src={eCityLogo} alt="" className="h-[16px]" />
            </div>
            <div className="ml-[5px]">E-City</div>
          </div>
        </Link>
        <div className="navberGetSolutionButton">
          <Link to="/contact">GET SOLUTION</Link>
        </div>
      </div>
    </div>
  );
}

export default BigScreenNavbar;
