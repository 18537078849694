/* eslint-disable max-len */
import React from "react";
import AccrediationsCardBackgroundImage from "../../../../assets/Components/Home/RecentlyCompletedWork/Accrediations/AccrediationsCardBackgroundImage.png";
import "./AccrediationsCard.css";

function AccrediationsCard({ cardDetails }) {
  return (
    <div className="relative h-[89px] aspect-[212/89]">
      {/* <div className="h-full flex justify-center absolute top-0 z-[-1]"><img src={AccrediationsCardBackgroundImage} alt="" className="h-full w-full aspect-[212/89] accrediationsCardContainer" /></div>
      <div className="h-full flex justify-center p-3"><img src={cardDetails.image} alt="" className="object-contain h-full" /></div> */}

      <div className="h-full flex justify-center">
        <img
          src={AccrediationsCardBackgroundImage}
          alt=""
          className="h-full w-full aspect-[212/89] accrediationsCardContainer"
        />
      </div>
      <div className="h-full flex justify-center p-3 absolute top-0 w-full">
        <img src={cardDetails.image} alt="" className="object-contain h-full" />
      </div>
    </div>
  );
}

export default AccrediationsCard;
