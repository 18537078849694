/* eslint-disable react/no-array-index-key */
import React from 'react';
// import mission from '../../../assets/Components/Ecity/ECitySolution/mission.png';

function ECitySolutionCard({ cardDetails }) {
  console.log('cardDetails = ', cardDetails)
  return (
    <div>
      <div className="flex my-[30px] gap-5">
        <div>
          <div className="h-[60px] w-[60px]"><img src={cardDetails.image} alt={cardDetails.imgae} className="object-cover" /></div>
        </div>
        <div>
          <div className="aboutOurCompanyCardTitle">{cardDetails.title || 'No Title Found'}</div>
          <div className="mt-[10px]">
            {cardDetails.contentList.map((content, index) => (<div key={index} className="sectionContent">{content}</div>))}
          </div>

        </div>
      </div>
      { (cardDetails.id !== 2) && <hr />}
    </div>
  );
}

export default ECitySolutionCard;
