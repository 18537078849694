import React from "react";
import "./ECityDetailsCards.css";
import ECityDetailsCard from "./ECityDetailsCard";

function ECityDetailsCards({ cardGallery }) {
  return (
    <div className="mt-5">
      <div className="eCityDetailsCardsTitle ">Components of E-ID</div>

      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-5 mt-5">
        {cardGallery.map((cardDetails, index) => (
          <ECityDetailsCard cardDetails={cardDetails} key={index} />
        ))}
      </div>
    </div>
  );
}

export default ECityDetailsCards;
