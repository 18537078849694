/* eslint-disable max-len */
import React from "react";
import bannerImage from "../../../assets/Components/Home/MapSection/bannerImage.png";
import phone from "../../../assets/Components/Home/MapSection/bxs_phone.svg";
import email from "../../../assets/Components/Home/MapSection/email.svg";
import fax from "../../../assets/Components/Home/MapSection/fax.svg";
// import leftSideBannerImage from '../../../assets/Components/Home/MapSection/leftSideBannerImage.png';
import location from "../../../assets/Components/Home/MapSection/location.svg";
import "./MapSection.css";
// import { useNavigate } from 'react-router-dom';
import ReuseableFooterBanner from "../../reuseableComponents/reuseableFooterBanner/ReuseableFooterBanner";

function MapSection() {
  // const navigate = useNavigate();

  return (
    <div className="mt-0 md:mt-[90px]">
      {/* <div className="container bg-[#2792D0] flex justify-between p-0">
        <div className="hidden lg:flex">
          <img src={bannerImage} alt="" className="w-[290px] h-[176px]" />
        </div>

        <div className="flex flex-wrap gap-5 justify-center items-center">
          <div className="bannerTitle p-5">We’re Ready Develop Your Site!</div>
        </div>

        <div className="hidden md:block max-w-[335px] w-full relative">
          <div className="h-full flex justify-center">
            <img
              src={leftSideBannerImage}
              alt=""
              className="h-full  aspect-[335/176] object-contain"
            />
          </div>
          <div className="flex justify-center items-center absolute h-full w-full top-0">
            <button
              type="button"
              className="bannerButtonText"
              onClick={() => navigate("/services/1")}
            >
              <nobr>MORE SERVICES</nobr>
            </button>
          </div>
        </div>
      </div> */}

      <ReuseableFooterBanner
        bannerImage={bannerImage}
        title="We’re Ready Develop Your Site!"
        buttonName="MORE SERVICES"
        url="/services/1"
      />

      <div className="relative">
        <div className="mapBackground">
          <div className="container flex flex-col justify-end h-full">
            <div className="grid md:grid-cols-2 gap-5 w-full">
              <div className="bg-[#FFFFFF] p-[38px]">
                <div className="mapCardTitle">Principal Office</div>

                <div className="flex gap-2 mapCardItemMargin mt-[20px]">
                  <div className="mapCardItem">SmartMatrix PTE Ltd.</div>
                </div>

                <div className="flex gap-2 mapCardItemMargin mb-5">
                  <div className="mapCardItem">UEN: 201105218W</div>
                </div>

                <div className="flex gap-2 mapCardItemMargin">
                  <div>
                    <img src={phone} alt="" className="h-[24]" />
                  </div>
                  <div className="mapCardItem">
                    +65 62419324 , +65 62419329 ,+ 65 62975359
                  </div>
                </div>

                <div className="flex gap-2 mapCardItemMargin">
                  <div>
                    <img src={email} alt="" className="h-[24]" />
                  </div>
                  <div className="mapCardItem">info@smartmatrix.com.sg</div>
                </div>

                <div className="flex gap-2 mapCardItemMargin">
                  <div>
                    <img src={location} alt="" className="h-[24]" />
                  </div>
                  <div className="mapCardItem">
                    114 Lavender Street, #05-54 CT Hub 2, Singapore 338729.
                  </div>
                </div>
              </div>

              <div className="bg-[#FFFFFF] p-[38px]">
                <div className="mapCardTitle">Bangladesh Branch Office</div>

                <div className="flex gap-2 mapCardItemMargin mt-[20px]">
                  <div>
                    <img src={phone} alt="" className="h-[24]" />
                  </div>
                  <div className="mapCardItem">+880-2-58316931-9</div>
                </div>

                <div className="flex gap-2 mapCardItemMargin">
                  <div>
                    <img src={email} alt="" className="h-[24]" />
                  </div>
                  <div className="mapCardItem">info@smartmatrix.com.sg</div>
                </div>

                <div className="flex gap-2 mapCardItemMargin">
                  <div>
                    <img src={fax} alt="" className="h-[24]" />
                  </div>
                  <div className="mapCardItem">+880-2-58316929</div>
                </div>

                <div className="flex gap-2 mapCardItemMargin">
                  <div>
                    <img src={location} alt="" className="h-[24]" />
                  </div>
                  <div className="mapCardItem">
                    28-29, Kakrail (Level # 14), Dhaka-1000, Bangladesh.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="mapBackground">
        <div className="relative container h-full w-full">
          <div className="grid md:grid-cols-2 gap-5 absolute bottom-0 w-full">
            <div className="bg-[#FFFFFF] p-[38px]">
              <div className="mapCardTitle">Principal Office</div>

              <div className="flex gap-2 mapCardItemMargin mt-[20px]">
                <div>
                  <img src={phone} alt="" className="h-[24]" />
                </div>
                <div className="mapCardItem">+65 6444 0326</div>
              </div>

              <div className="flex gap-2 mapCardItemMargin">
                <div>
                  <img src={email} alt="" className="h-[24]" />
                </div>
                <div className="mapCardItem">info@smartmatrix.com.sg</div>
              </div>

              <div className="flex gap-2 mapCardItemMargin">
                <div>
                  <img src={location} alt="" className="h-[24]" />
                </div>
                <div className="mapCardItem">
                  114 Lavender Street, #05-54 CT Hub 2, Singapore 338729.
                </div>
              </div>
            </div>

            <div className="bg-[#FFFFFF] p-[38px]">
              <div className="mapCardTitle">Bangladesh Branch Office</div>

              <div className="flex gap-2 mapCardItemMargin mt-[20px]">
                <div>
                  <img src={phone} alt="" className="h-[24]" />
                </div>
                <div className="mapCardItem">+880-2-58316931-9</div>
              </div>

              <div className="flex gap-2 mapCardItemMargin">
                <div>
                  <img src={email} alt="" className="h-[24]" />
                </div>
                <div className="mapCardItem">info@smartmatrix.com.sg</div>
              </div>

              <div className="flex gap-2 mapCardItemMargin">
                <div>
                  <img src={fax} alt="" className="h-[24]" />
                </div>
                <div className="mapCardItem">+880-2-58316929</div>
              </div>

              <div className="flex gap-2 mapCardItemMargin">
                <div>
                  <img src={location} alt="" className="h-[24]" />
                </div>
                <div className="mapCardItem">
                  28-29, Kakrail (Level # 14), Dhaka-1000, Bangladesh.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default MapSection;
