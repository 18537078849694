/* eslint-disable no-alert */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { faChevronRight, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Footer from "../../Components/Footer/Footer";
import MiniReUseableTitle from "../../Components/Home/MiniReUseableTitle/MiniReUseableTitle";
import Navbar from "../../Components/Navbar/Navbar";
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import { careerCardData } from "../../store/careerStore";
import "../About/About.css";
import "./Career.css";

function Career() {
  const divRef = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitResponse, setSubmitRespone] = useState(false);

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // console.log()
  useEffect(() => {
    if (submitResponse) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [submitResponse]);

  const handleSubmit = (event) => {
    if (name === "") {
      alert("Please Enter Your Name");
    } else if (email === "") {
      alert("Please Enter Your Email");
    } else {
      setLoading(true);
      event.preventDefault();

      const formDataToSend = new FormData();
      formDataToSend.append("name", name);
      formDataToSend.append("email", email);
      formDataToSend.append("area", message);
      formDataToSend.append("file", file);

      axios
        .post(
          "https://api.smartmatrix.life:446/api/proposal/",
          formDataToSend,
          {
            headers: {
              Token: "61748#910388%3@-292$",
            },
          }
        )
        .then((response) => {
          // Handle successful response
          setLoading(false);
          console.log(response.data);
          setSubmitRespone(true);
          window.location.reload();
        })
        .catch((error) => {
          // Handle error
          setLoading(false);
          console.error(error);
        });
    }
  };
  return (
    <div>
      <Navbar />
      <div className="relative">
        <div className="career-hero">
          <div className="container flex flex-col justify-center h-full">
            <div className="flex flex-row heroTitle">Career</div>
            <div className="mt-6 flex flex-row items-center">
              <FontAwesomeIcon icon={faHouse} color="white" />
              <FontAwesomeIcon
                icon={faChevronRight}
                color="white"
                style={{ paddingLeft: "1rem" }}
              />
              <p
                className="text-white"
                style={{
                  paddingLeft: "1rem",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Career
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24 container">
        <div className="flex flex-col items-center justify-center">
          <MiniReUseableTitle title="BENEFITS AND PERKS" color="#195CA5" />
          <div className="mt-5 flex flex-row main-title">
            We put our employees first
          </div>
          <div className="my-16 grid grid-cols-1 md:2 lg:grid-cols-3 gap-6 justify-center">
            {careerCardData.map((data) => (
              <div
                key={data.id}
                className="p-5 flex gap-4 jusify-center career-card"
              >
                <div className="flex flex-col">
                  <img src={data.image} alt={data.alt} />
                </div>
                <div className="flex flex-col">
                  <p className="career-card-title">{data.title}</p>
                  <p className="mt-4 career-card-desc">{data.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mb-6 relative">
        <div className="career-footer">
          <div className="container flex flex-col justify-center items-center h-full">
            <div className="flex flex-row career-footer-title">
              <p>
                Become part of a worldwide network of skilled professionals
                ready to create an impact.
              </p>
            </div>
            <button
              type="button"
              className="mt-9 DiscoverMoreButton inline-block"
              onClick={() =>
                divRef.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              JOIN SMARTMATRIX
            </button>
          </div>
        </div>
      </div>
      <div className="mt-24 bg-[#F4F5F8]">
        <div className="container" ref={divRef}>
          <div className="pt-16 pb-10">
            <MiniReUseableTitle title="DROP YOUR CV" color="#195CA5" />
          </div>
          <div className="grid grid-cols-2 justify-between">
            <p className="main-title">
              We Are Looking For Talented Individuals
            </p>
            <p className="card-text text-[16px]">
              We’re building a better way to work, fueled by transparency,
              trust, and technology that is a force for positive change for the
              hourly workforce
            </p>
          </div>
          <div className="py-16">
            <div className="flex w-full gap-3">
              <input
                type="text"
                placeholder="Enter Your Name"
                className="w-1/2 form-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Enter Your Email"
                className="w-1/2 form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mt-6 flex">
              <input
                type="text"
                placeholder="Enter Your Preferred Area"
                className="w-full form-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <label
              className="mt-6 pt-6 pb-6 bg-white"
              htmlFor="inputTag"
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                border: "1px solid #DCE0E4",
                borderRadius: "5px",
              }}
            >
              <div className="flex flex-col justify-center items-center">
                <span className="font-normal text-[16px] text-[#858E96]">
                  Upload CV
                </span>
                <span className="font-semibold text-[16px] text-[#2792D0]">
                  {file ? `Uploaded File: ${file?.name}` : "Browse Now"}
                </span>
                <input
                  id="inputTag"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
              </div>
            </label>
            {loading ? (
              <div className="my-4 flex justify-center">
                <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin" />
              </div>
            ) : (
              <></>
            )}
            {submitResponse ? (
              <div>
                <div className="my-4 flex justify-center text-green-500">
                  <p>Your Response Submitted Successfully</p>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="mt-6 flex justify-center">
              {loading ? (
                <button
                  type="button"
                  className="DiscoverMoreButton inline-block opacity-60 cursor-not-allowed"
                  disabled
                >
                  Apply Now
                </button>
              ) : (
                <button
                  type="button"
                  className="DiscoverMoreButton inline-block"
                  onClick={handleSubmit}
                >
                  Apply Now
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Career;
