/* eslint-disable no-alert */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { faChevronRight, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import MiniReUseableTitle from "../../Components/Home/MiniReUseableTitle/MiniReUseableTitle";
import Navbar from "../../Components/Navbar/Navbar";
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import emailImg from "../../assets/Components/Contact/email.png";
import contactImg from "../../assets/Components/Contact/phone.png";
import "../About/About.css";
import "./Contact.css";

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitResponse, setSubmitRespone] = useState(false);

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (submitResponse) {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }, [submitResponse]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (event) => {
    if (name === "") {
      alert("Please Enter Your Name");
    } else if (email === "") {
      alert("Please Enter Your Email");
    } else {
      setLoading(true);
      event.preventDefault();

      const formDataToSend = new FormData();
      formDataToSend.append("name", name);
      formDataToSend.append("email", email);
      formDataToSend.append("area", message);
      formDataToSend.append("file", file);

      axios
        .post(
          "https://api.smartmatrix.life:446/api/proposal/",
          formDataToSend,
          {
            headers: {
              Token: "61748#910388%3@-292$",
            },
          }
        )
        .then((response) => {
          // Handle successful response
          setLoading(false);
          console.log(response.data);
          setSubmitRespone(true);
          window.location.reload();
        })
        .catch((error) => {
          // Handle error
          setLoading(false);
          console.error(error);
        });
    }
  };
  return (
    <div>
      <Navbar />
      <div className="relative">
        <div className="contact-hero">
          <div className="container flex flex-col justify-center h-full">
            <div className="flex flex-row heroTitle">Contact Us</div>
            <div className="mt-6 flex flex-row items-center">
              <FontAwesomeIcon icon={faHouse} color="white" />
              <FontAwesomeIcon
                icon={faChevronRight}
                color="white"
                style={{ paddingLeft: "1rem" }}
              />
              <p
                className="text-white"
                style={{
                  paddingLeft: "1rem",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Get Solution
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24 container">
        <div className="flex flex-col items-center justify-center">
          <MiniReUseableTitle title="NEED ANY HELP?" color="#195CA5" />
          <div className="mt-5 flex flex-row main-title">
            Get in Touch With Us
          </div>
        </div>
        <div className="my-16 grid grid-cols-1 md:grid-cols-2 gap-6 justify-center">
          <div className="px-16 py-10 border-t-[10px] border-l border-r border-b border-[#2792D0]">
            <p className="card-title">Singapore Principal Office</p>
            <div className="my-2 border-[#2792D0] max-w-[96px] border" />
            <p className="mt-8 card-text text-[20px]">SmartMatrix PTE Ltd.</p>
            <p className="my-2 card-text text-[20px]">UEN: 201105218W</p>
            <p className="my-4 card-text text-[20px]">
              114 Lavender Street, #05-54 CT Hub 2, Singapore 338729.
            </p>
            <div className="flex flex-wrap gap-4 items-center">
              <div className="p-2 icon-container">
                <img
                  src={emailImg}
                  alt="emailImg"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <div>
                <p className="icon-title">Email:</p>
                <p className="card-text text-[18px]">info@smartmatrix.com.sg</p>
              </div>
            </div>
            <div className="mt-4 flex flex-row gap-4 items-center">
              <div className="p-2 icon-container">
                <img
                  src={contactImg}
                  alt="contactImg"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <div>
                <p className="icon-title">Phone:</p>
                <p className="card-text text-[18px]">
                  +65 62419324 , +65 62419329 ,+ 65 62975359
                </p>
              </div>
            </div>
          </div>
          <div className="px-16 py-10 border-t-[10px] border-l border-r border-b border-[#2792D0]">
            <p className="card-title">Bangladesh Branch Office</p>
            <div className="my-2 border-[#2792D0] max-w-[96px] border" />
            <p className="my-8 card-text text-[20px]">
              28-29, Kakrail (Level # 14), Dhaka-1000, Bangladesh.
            </p>
            <div className="flex flex-wrap gap-4 items-center">
              <div className="p-2 icon-container">
                <img
                  src={emailImg}
                  alt="emailImg"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <div>
                <p className="icon-title">Email:</p>
                <p className="card-text text-[18px]">info@smartmatrix.com.sg</p>
              </div>
            </div>
            <div className="mt-4 flex flex-row gap-4 items-center">
              <div className="p-2 icon-container">
                <img
                  src={contactImg}
                  alt="contactImg"
                  className="h-[48px] w-[48px]"
                />
              </div>
              <div>
                <p className="icon-title">Phone:</p>
                <p className="card-text text-[18px]">+880-2-58316931-9</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 bg-[#F4F5F8]">
        <div className="container">
          <div className="pt-16 pb-10">
            <MiniReUseableTitle title="CONTACT US" color="#195CA5" />
          </div>
          <div className="grid grid-cols-2 justify-between">
            <p className="main-title">Feel Free To Contact Us Now</p>
            <p className="card-text text-[16px]">
              Got a project in mind? We’d love to hear about it. Take five
              minutes to fill out our project form so that we can get to know
              you and understand your project.
            </p>
          </div>
          <div className="py-16">
            <div className="flex w-full gap-3">
              <input
                type="text"
                placeholder="Enter Your Name"
                className="w-1/2 form-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Enter Your Email"
                className="w-1/2 form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {/* <div className="mt-6 flex">
              <input type="text" placeholder="Enter Subject" className="w-full form-input" />
            </div> */}
            <div className="mt-6 flex">
              <textarea
                type="text"
                placeholder="Your Message "
                className="w-full pt-2 form-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <label
              className="mt-6 pt-6 pb-6 bg-white"
              htmlFor="inputTag"
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                border: "1px solid #DCE0E4",
                borderRadius: "5px",
              }}
            >
              <div className="flex flex-col justify-center items-center">
                <span className="font-normal text-[16px] text-[#858E96]">
                  Upload Proposal or Query
                </span>
                <span className="font-semibold text-[16px] text-[#2792D0]">
                  {file ? `Uploaded File: ${file?.name}` : "Browse Now"}
                </span>
                <input
                  id="inputTag"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
              </div>
            </label>
            {loading ? (
              <div className="my-4 flex justify-center">
                <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin" />
              </div>
            ) : (
              <></>
            )}
            {submitResponse ? (
              <div>
                <div className="my-4 flex justify-center text-green-500">
                  <p>Your Response Submitted Successfully</p>
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="mt-6 flex justify-center">
              {loading ? (
                <button
                  type="button"
                  className="DiscoverMoreButton inline-block opacity-60 cursor-not-allowed"
                  disabled
                >
                  FREE CONSULTANCY
                </button>
              ) : (
                <button
                  type="button"
                  className="DiscoverMoreButton inline-block"
                  onClick={handleSubmit}
                >
                  FREE CONSULTANCY
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
