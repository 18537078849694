/* eslint-disable max-len */
import React from "react";
import workflow from "../../../assets/Components/Home/Workflow/workflow.webp";
// import MiniReUseableTitle from '../MiniReUseableTitle/MiniReUseableTitle';
import MiniReUseableTitle from "../MiniReUseableTitle/MiniReUseableTitle";
import "./Workflow.css";

function Workflow() {
  return (
    <div className="workflowBackgroundImage sectionContentMargin1">
      <div className="container py-[60px]">
        <div className="flex justify-between items-end gap-5">
          <div className="flex-1">
            <div className="flex flex-col items-center justify-center max-w-[404px] w-full">
              {/* <div className="flex items-center">
                <div className="workflowText">Workflow</div>

                <div className="bg-[#195CA5] w-[35px] h-[1.5px] ml-[10px]" />
              </div> */}

              <MiniReUseableTitle title="Workflow" color="#195CA5" />

              <div className="workflowTitle mt-[20px]">
                Agile, Quality, Successful Digital Solutions
              </div>
            </div>
          </div>

          <div className="workflowContent  flex-1">
            SmartMatrix, a globally recognized IT company, adheres to an agile
            development process, prioritizing quality without compromising
            time-to-market for clients. Proficient in delivering success,
            SmartMatrix ensures effective project outcomes.
          </div>
        </div>

        <div className="max-h-[232px] my-[60px]">
          <img src={workflow} alt="" className="object-cover" />
        </div>
      </div>
    </div>
  );
}

export default Workflow;
