/* eslint-disable react/no-array-index-key */
import React from 'react';
import './DevopsSolutionCard.css';

function DevopsSolutionCard({ cardDetails }) {
  return (
    <div className="devopsSolutionCardContainer">
      <div className="flex items-stretch gap-5">

        <div className="flex justify-center items-center">
          <div className="devopsSolutionCardImageContainer rounded-full">
            <img src={cardDetails.image} alt="" className="devopsSolutionCardImage" />
          </div>
        </div>

        <div className="">
          <div className="sectionListTitle">{cardDetails.title}</div>
          <div className="">
            {cardDetails.list.map((content, index) => <div className="sectionListListContent devopsSolutionCardListMargin" key={index}>{content}</div>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevopsSolutionCard;
