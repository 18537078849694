import React from 'react';
import './TechnologyThornWeUseCard.css';

function TechnologyThornWeUseCard({ cardDetails }) {
  return (
    <div className="flex items-center gap-5 technologyThornWeUseCardContainer">

      <div className="bg-[#2792D0]/10 p-[10px]"><img src={cardDetails.image} alt="" className="h-[40px] w-[40px] object-scale-down" /></div>

      <div className="technologyThornWeUseCardTitle">{cardDetails.title}</div>
    </div>
  );
}

export default TechnologyThornWeUseCard;
