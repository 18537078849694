import React from 'react';
import BigScreenNavbar from './BigScreenNavbar/BigScreenNavbar';
import NavbarBelowlgScreen from './Navbar-Below-lg-Screens/NavbarBelowlgScreen';

function Navbar() {
  return (
    <>
      <BigScreenNavbar />
      <NavbarBelowlgScreen />
    </>
  );
}

export default Navbar;
