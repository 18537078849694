/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

function LatestTechnologiesItem() {
  const [readMoreStatus, setReadMoreStatus] = useState(true);

  let content;

  if (readMoreStatus) {
    content = 'SmartMatrix embraces the latest technologies in software development, leveraging cutting-edge tools like AI, blockchain, cloud...'
  } else {
    content = 'SmartMatrix embraces the latest technologies in software development, leveraging cutting-edge tools like AI, blockchain, cloud computing, and IoT. Our approach integrates these advancements to create innovative, scalable, and efficient solutions, ensuring that our clients benefit from state-of-the-art technology for their software projects.'
  }

  return (

    <div className="flex gap-3">
      <div className="whyChooseSmartmatrixListItemNumber">01.</div>
      <div>
        <div className="sectionListTitle">Latest Technologies</div>
        <div className="sectionListListContent">
          {content}
          {readMoreStatus && <span className="text-[#195CA5] font-semibold cursor-pointer" onClick={() => setReadMoreStatus(false)}>Read More</span>}
          {!readMoreStatus && <span className="text-[#195CA5] font-semibold cursor-pointer" onClick={() => setReadMoreStatus(true)}>Read Less</span>}
        </div>
      </div>
    </div>
  );
}

export default LatestTechnologiesItem;
