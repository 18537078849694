/* eslint-disable max-len */
import React from "react";
// import ClientsImg3 from "../../../assets/Components/Ecity/OurClients/ClientsImg3.png";
import HP from "../../../assets/Components/Home/ClientAndConcern/HP.png";
import Pub from "../../../assets/Components/Home/ClientAndConcern/Pub.png";
import como from "../../../assets/Components/Home/ClientAndConcern/como.png";
import dell from "../../../assets/Components/Home/ClientAndConcern/dell.png";
import honeywell from "../../../assets/Components/Home/ClientAndConcern/honeywell.png";
import hopeland from "../../../assets/Components/Home/ClientAndConcern/hopeland.png";
import omni from "../../../assets/Components/Home/ClientAndConcern/omni.png";
import sam from "../../../assets/Components/Home/ClientAndConcern/sam.png";
// import sap from "../../../assets/Components/Home/ClientAndConcern/sap.png";
import smart from "../../../assets/Components/Home/ClientAndConcern/smart.png";
import tcb from "../../../assets/Components/Home/ClientAndConcern/tcb.png";
import zebra from "../../../assets/Components/Home/ClientAndConcern/zebra.png";
import Muehlbauer from "../../../assets/Components/Home/ClientAndConcern/Muehlbauer.png";
import ambu from "../../../assets/Components/Home/ClientAndConcern/ambu.png";
import selp from "../../../assets/Components/Home/ClientAndConcern/selp.png";
import demcon from "../../../assets/Components/Home/ClientAndConcern/demcon.png";
import "./TrustedByGlobalBrands.css";

function TrustedByGlobalBrands() {
  return (
    <div className="bg-[#F4F4F4] pb-[45px] md:pb-[50px] lg:pb-[60px] xl:pb-[90px]">
      <div className="container">
        <div className="pt-[50px] sm:pt-[60px] lg:pt-[230px] px-5 sm:px-0">
          {/* <div className="lg:grid grid-cols-2 translate-y-[-80px] hidden">
          <div />
          <div className="flex justify-center">
            <img src={ClientsImg3} alt="" className="max-h-[230px] " />
          </div>
        </div> */}

          <div className="trustedByGlobalBrandsTitle">
            Trusted By Global Brands
          </div>

          <div>
            <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-5">
              {/* columnn1 */}
              <div className="grid gap-5">
                <div className="trustedByGlobalBrandsCardContainer">
                  <div className="flex justify-center">
                    <img
                      src={Pub}
                      alt=""
                      className="object-scale-down max-w-[257px] max-h-[57px] w-full"
                    />
                  </div>
                  <div className="trustedByGlobalBrandsCardTitle">
                    {
                      "‘‘ Efficient Tracking Made Easy – Smart Matrix's barcode and RFID solutions have increased supply chain visibility for Our company. ’’"
                    }
                  </div>
                </div>

                <div className="trustedByGlobalBrandsCardContainer py-[54px] ">
                  <div className="flex justify-center">
                    <img
                      src={honeywell}
                      alt=""
                      className="object-scale-down max-w-[270px] max-h-[49px] w-full"
                    />
                  </div>
                </div>

                <div className="trustedByGlobalBrandsCardContainer">
                  <div className="flex justify-center">
                    <img
                      src={como}
                      alt=""
                      className="object-scale-down max-w-[285px] max-h-[108px] w-full"
                    />
                  </div>
                  <div className="trustedByGlobalBrandsCardTitle">
                    {
                      "‘‘ SmartMatrix Commodity proudly acknowledges the exceptional services provided by SmartMatrix. We are thoroughly satisfied with the results achieved and appreciate the collaborative spirit that SmartMatrix consistently demonstrates. It's reassuring to work with a company that not only understands the intricacies of our industry but also excels in providing solutions that make a tangible impact on our success. We look forward to a continued and fruitful partnership with SmartMatrix. ’’"
                    }
                  </div>
                </div>

                <div className="trustedByGlobalBrandsCardContainer py-[29px] px-[33px]">
                  <div className="flex justify-center">
                    <img
                      src={sam}
                      alt=""
                      className="object-scale-down max-w-[302px] max-h-[100px] w-full"
                    />
                  </div>
                </div>

                <div className="trustedByGlobalBrandsCardContainer py-[29px] px-[33px]">
                  <div className="flex justify-center">
                    <img
                      src={ambu}
                      alt="ambu"
                      className="object-scale-down max-w-[302px] max-h-[100px] w-full"
                    />
                  </div>
                </div>

                {/* <div className="trustedByGlobalBrandsCardContainer">
                <div className="flex justify-center">
                  <img
                    src={sap}
                    alt=""
                    className="object-scale-down w-[206px] h-[98px]"
                  />
                </div>
                <div className="trustedByGlobalBrandsCardTitle">
                  {
                    "‘‘ SAP is pleased to commend SmartMatrix for their exceptional services in the realm of IT solutions. Their team's expertise, coupled with a commitment to innovation, has significantly enhanced our operational efficiency. SmartMatrix consistently delivers cutting-edge solutions, ensuring SAP remains at the forefront of technological advancement. ’’"
                  }
                </div>
              </div> */}
              </div>

              {/* columnn2 */}
              <div className="grid gap-5">
                <div className="trustedByGlobalBrandsCardContainer py-[29px] px-[28px]">
                  <div className="flex justify-center">
                    <img
                      src={zebra}
                      alt=""
                      className="object-scale-down max-w-[290px] max-h-[100px] w-full"
                    />
                  </div>
                </div>

                <div className="trustedByGlobalBrandsCardContainer">
                  <div className="flex justify-center">
                    <img
                      src={hopeland}
                      alt=""
                      className="object-scale-down max-w-[249px] max-h-[67px] w-full"
                    />
                  </div>
                  <div className="trustedByGlobalBrandsCardTitle">
                    ‘‘ Their collaboration and achievements have left us highly
                    content. Granting SmartMatrix the opportunity to earn our
                    trust has been a rewarding decision. ’’
                  </div>
                </div>

                <div className="trustedByGlobalBrandsCardContainer">
                  <div className="flex justify-center">
                    <img
                      src={HP}
                      alt=""
                      className="object-scale-down max-w-[201px] max-h-[84px] w-full"
                    />
                  </div>
                  <div className="trustedByGlobalBrandsCardTitle">
                    {
                      "‘‘ SmartMatrix's commitment to understanding our unique challenges and providing adapt solutions showcases their client-centric approach. As a global leader in technology solutions, HPE values the strategic partnership with SmartMatrix, and we look forward to continued collaboration that drives technological excellence and business success. ’’"
                    }
                  </div>
                </div>

                <div className="trustedByGlobalBrandsCardContainer py-[22px] px-[125px]">
                  <div className="flex justify-center">
                    <img
                      src={smart}
                      alt=""
                      className="object-scale-down max-w-[118px] max-h-[114px] w-full"
                    />
                  </div>
                </div>
                <div className="trustedByGlobalBrandsCardContainer py-[22px] px-[125px]">
                  <div className="flex justify-center">
                    <img
                      src={selp}
                      alt="selp"
                      className="object-scale-down max-w-[118px] max-h-[114px] w-full"
                    />
                  </div>
                </div>
              </div>

              {/* columnn3 old*/}

              {/* columnn3 */}
              <div className="grid gap-5 grid-cols-1 md:grid-cols-2 xl:grid-cols-1 md:col-span-2 xl:col-span-1 ">
                <div className="trustedByGlobalBrandsCardContainer">
                  <div className="flex justify-center">
                    <img
                      src={omni}
                      alt=""
                      className="object-scale-down max-w-[268px] max-h-[62px] w-full"
                    />
                  </div>
                  <div className="trustedByGlobalBrandsCardTitle">
                    {
                      "‘‘ Omni ID is delighted to share our positive experience working with SmartMatrix. Their commitment to excellence in IT solutions and services has greatly contributed to the success of our projects. SmartMatrix's innovative approach, attention to detail, and responsive team make them a standout partner. We appreciate their dedication to understanding our unique needs and delivering tailored solutions that have significantly enhanced our operational efficiency.’’"
                    }
                  </div>
                </div>

                <div className="trustedByGlobalBrandsCardContainer self-start">
                  <div className="flex justify-center">
                    <img
                      src={tcb}
                      alt=""
                      className="object-scale-down max-w-[109px] max-h-[107px] w-full"
                    />
                  </div>
                  <div className="trustedByGlobalBrandsCardTitle">
                    ‘‘ SmartMatrix has demonstrated a deep understanding of our
                    trading requirements and consistently delivered IT solutions
                    and services. Their expertise in the field, coupled with a
                    commitment to innovation, has significantly enhanced our
                    operational efficiency. TCB appreciates the strategic
                    insights and collaborative spirit that SmartMatrix brings to
                    the table. ’’
                  </div>
                </div>

                <div className="trustedByGlobalBrandsCardContainer py-[29px] px-[33px]">
                  <div className="flex justify-center">
                    <img
                      src={Muehlbauer}
                      alt=""
                      className="object-scale-down w-[302px] h-[100px]"
                    />
                  </div>
                </div>

                <div className="trustedByGlobalBrandsCardContainer py-[52px] px-[37px] self-start">
                  <div className="flex justify-center">
                    <img
                      src={dell}
                      alt=""
                      className="object-scale-down max-w-[293px] max-h-[52px] w-full"
                    />
                  </div>
                </div>
                <div className="trustedByGlobalBrandsCardContainer py-[52px] px-[37px] self-start">
                  <div className="flex justify-center">
                    <img
                      src={demcon}
                      alt="demcon"
                      className="object-scale-down max-w-[293px] max-h-[52px] w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrustedByGlobalBrands;
