import React from 'react';
import './Hamburger.css';

function Hamburger({ showDropdownNavbarMenu, setShowDropdownNavbarMenu }) {
  const toggleMenu = () => {
    setShowDropdownNavbarMenu(!showDropdownNavbarMenu);
  }

  return (
    <div className="mt-[2px]">
      <button
        id="menu-btn"
        type="button"
        className={`block hamburger focus:outline-none ${showDropdownNavbarMenu ? 'open' : ''}`}
        onClick={toggleMenu}
      >
        <span className="hamburger-top" />
        <span className="hamburger-middle" />
        <span className="hamburger-bottom" />
      </button>
    </div>
  );
}

export default Hamburger;
