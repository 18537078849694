/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import About from './pages/About/About';
import Career from './pages/Career/Career';
import ClientConcern from './pages/ClientConcern/ClientConcern';
import ContactUs from './pages/Contact/Contact';
import ECityDetails from './pages/Ecity/ECityDetails';
import Ecity from './pages/Ecity/Ecity';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ecity" element={<Ecity />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/career" element={<Career />} />
        <Route path="/ecitydetails/:id" element={<ECityDetails />} />
        <Route path="/client" element={<ClientConcern />} />
        <Route path="/services/:id" element={<Services />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
