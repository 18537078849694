/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import eCityLogo from "../../../../assets/Navbar/ecitylogo.svg";
import ServiceDropdown from "./ServiceDropdown/ServiceDropdown";
import SolutionsDropdown from "./SolutionsDropdown/SolutionsDropdown";

function DropdownNavbar({setShowDropdownNavbarMenu}) {
  const [selectedDropdown, setSelectedDropdown] = useState("");
  // const containerRef = useRef(null);
  // const [childOffsets, setChildOffsets] = useState([]);

  // useEffect(() => {
  //   if (containerRef?.current?.children) {
  //     console.log('containerRef = ', containerRef.current.children)
  //   }
  // }, [containerRef]);

  // useEffect(() => {
  //   const childDivs = Array.from(containerRef.current.children);
  //   const offsets = childDivs.map((child) => child.offsetTop);
  //   console.log('childDivs = ', childDivs);
  //   console.log('offsets', offsets);

  //   setChildOffsets(offsets);
  // }, []);

  const selectedDropdownHandler = (value) => {
    if (selectedDropdown !== value) {
      setSelectedDropdown(value);
    }
    if (selectedDropdown === value) {
      setSelectedDropdown("");
    }
  };

  return (
    <div className="border-t border-primary/10 mt-[5px]">
      <div>
        <div
          className={`block text-left py-3 text-base font-normal leading-[19.09px] hover:bg-gray-100 px-2 ${
            selectedDropdown === "Services" ? "bg-gray-100" : ""
          }`}
          onClick={() => selectedDropdownHandler("Services")}
        >
          <div className="flex justify-between">
            Services
            <div className="ml-[5px]">
              {selectedDropdown !== "Services" && (
                <FontAwesomeIcon icon={faChevronDown} size="sm" />
              )}
              {selectedDropdown === "Services" && (
                <FontAwesomeIcon icon={faChevronUp} size="sm" />
              )}
            </div>
          </div>
        </div>
        {selectedDropdown === "Services" && (
          <div className="px-4">
            <ServiceDropdown setShowDropdownNavbarMenu={setShowDropdownNavbarMenu} />
          </div>
        )}
      </div>

      <div>
        <div
          className={`block text-left border-t py-3 border-[#e5e7eb] text-base font-normal leading-[19.09px] hover:bg-gray-100 px-2 ${
            selectedDropdown === "Solutions" ? "bg-gray-100" : ""
          }`}
          onClick={() => selectedDropdownHandler("Solutions")}
        >
          <div className="flex justify-between">
            Solutions
            <div className="ml-[5px]">
              {selectedDropdown !== "Solutions" && (
                <FontAwesomeIcon icon={faChevronDown} size="sm" />
              )}
              {selectedDropdown === "Solutions" && (
                <FontAwesomeIcon icon={faChevronUp} size="sm" />
              )}
            </div>
          </div>
        </div>
        {selectedDropdown === "Solutions" && (
          <div className="px-4">
            <SolutionsDropdown setShowDropdownNavbarMenu={setShowDropdownNavbarMenu} />
          </div>
        )}
      </div>

      <Link
        to="/client"
        className="block text-left border-t py-3 border-[#e5e7eb] text-base font-normal leading-[19.09px]  hover:bg-gray-100 px-2"
      >
        Our Clients
      </Link>
      <Link
        to="/about"
        className="block text-left border-t py-3 border-[#e5e7eb] text-base font-normal leading-[19.09px]  hover:bg-gray-100 px-2"
      >
        About Us
      </Link>
      <Link
        to="/career"
        className="block text-left border-t py-3 border-[#e5e7eb]  text-base font-normal leading-[19.09px]  hover:bg-gray-100 px-2"
      >
        Career
      </Link>

      <Link
        to="/ecity"
        className="block text-left border-t py-3 border-[#e5e7eb]  text-base font-normal leading-[19.09px]  hover:bg-gray-100 px-2"
      >
        <div className="flex items-center">
          <div>
            <img src={eCityLogo} alt="" className="h-[16px]" />
          </div>
          <div className="ml-[5px]">E-City</div>
        </div>
      </Link>
    </div>
  );
}

export default DropdownNavbar;
