/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React from "react";
import basis from "../../../assets/Components/Home/RecentlyCompletedWork/Accrediations/basis.png";
import biz2 from "../../../assets/Components/Home/RecentlyCompletedWork/Accrediations/biz2.png";
import bizsafe1 from "../../../assets/Components/Home/RecentlyCompletedWork/Accrediations/bizsafe1.png";
import dpp from "../../../assets/Components/Home/RecentlyCompletedWork/Accrediations/dpp.png";
import et from "../../../assets/Components/Home/RecentlyCompletedWork/Accrediations/et.png";
import HappyCustomers from "../../../assets/Components/Home/RecentlyCompletedWork/Excellence/HappyCustomers.png";
import ProjectCompleted from "../../../assets/Components/Home/RecentlyCompletedWork/Excellence/ProjectCompleted.png";
import YearsOfExperience from "../../../assets/Components/Home/RecentlyCompletedWork/Excellence/YearsOfExperience.png";
import { recentlyCompletedWorkCard } from "../../../store/store";
import MiniReUseableTitle from "../MiniReUseableTitle/MiniReUseableTitle";
import AccrediationsCard from "./Accrediations/AccrediationsCard";
import ExcellenceCard from "./Excellence/ExcellenceCard";
import "./RecentlyCompletedWork.css";
import RecentlyCompletedWorkCard from "./RecentlyCompletedWorkCard";

const ExcellenceCardData = [
  {
    id: 1,
    image: YearsOfExperience,
    title: "YEARS OF EXPERIENCE",
    numberText: "10+",
  },
  {
    id: 2,
    image: ProjectCompleted,
    title: "PROJECT COMPLETED",
    numberText: "150+",
  },
  {
    id: 3,
    image: HappyCustomers,
    title: "HAPPY CUSTOMERS",
    numberText: "3600+",
  },
];

const AccrediationsCardData = [
  {
    id: 1,
    image: biz2,
  },
  {
    id: 2,
    image: bizsafe1,
  },
  {
    id: 3,
    image: basis,
  },
  {
    id: 4,
    image: et,
  },
  {
    id: 5,
    image: dpp,
  },
];

function RecentlyCompletedWork() {
  return (
    <div className="recentlyCompletedWorkBackGroundImage overflow-hidden">
      <div className="mt-[90px] container">
        <div>
          <MiniReUseableTitle title="RECENTLY COMPLETED WORK" color="#195CA5" />
        </div>

        <div className="grid grid-flow-row lg:grid-cols-2 gap-5 mt-[20px]">
          <div className="sectionTitle">Improve & Enhance theTech Projects</div>
          <div className="sectionContent">
            Recent Software Triumphs: Innovative solutions, satisfied clients,
            cutting-edge technology integration—experience excellence with us
          </div>
        </div>

        <div className="grid grid-flow-row grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-5 mt-[60px]">
          {recentlyCompletedWorkCard.map((cardDetails, index) => (
            <RecentlyCompletedWorkCard cardDetails={cardDetails} key={index} />
          ))}
        </div>

        {/* Excellence Section */}
        <div className="mt-[90px]">
          <MiniReUseableTitle title="Excellence Section" color="#195CA5" />
        </div>

        <div className="grid grid-flow-row lg:grid-cols-2 gap-5 mt-[20px]">
          <div>
            <div className="sectionTitle">
              Our baseline standard for delivering services to clients.
            </div>
            <div className="sectionContent">
              We uphold a minimum standard for client delivery, ensuring that
              our services consistently meet the baseline requirements for
              quality and excellence in every project.
            </div>
          </div>
          <div className="flex flex-wrap justify-end gap-5 gap-y-[50px]">
            {ExcellenceCardData.map((cardDetails, index) => (
              <ExcellenceCard cardDetails={cardDetails} key={index} />
            ))}
          </div>
        </div>

        {/* Accrediations Section */}
        <div className="mt-[60px]">
          <MiniReUseableTitle title="ACCREDIATIONS" color="#195CA5" />
          <div className="my-[30px]">
            <div className="flex flex-wrap justify-between gap-3">
              {AccrediationsCardData.map((cardDetails, index) => (
                <AccrediationsCard cardDetails={cardDetails} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecentlyCompletedWork;
