/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import TechManagementImage from "../../../assets/Components/Home/TechManagement/TechManagementImage.png";
import bulb from "../../../assets/Components/Home/TechManagement/bulb.png";
import "./TechManagement.css";

function TechManagement() {
  return (
    <div className="techManagementBackGround sectionContentMargin1">
      {/* <div className="lg:hidden mt-7">
        <img src={TechManagementImage} alt="" className="" />
      </div> */}

      <div className="relative">
        <div className="techManagementImageContainer hidden lg:block">
          <img
            src={TechManagementImage}
            alt=""
            className="object-cover h-full w-full"
          />
        </div>

        <div className="container">
          <div className="grid grid-flow-row lg:grid-cols-2 gap-0">
            <div className="lg:mr-[50px]">
              <div className="flex flex-col justify-center h-full py-[40px] md:py-[60px] device-management">
                {/* <MiniReUseableTitle title="TECH MANAGEMENT" color="#2792D0" /> */}

                <div className="flex items-center">
                  <div className="techManagementMiniTitle">
                    DEVICE MANAGEMENT
                  </div>
                  <div className="bg-[#2792D0] h-[1.5px] w-[35px] ml-[10px]" />
                </div>

                <div className="sectionTitle text-white techManagementLeftContentMargin">
                  Customised Electronic Devices Solutions & Services at your
                  Fingertips
                </div>
                <div className="sectionContent techManagementLeftContentMargin">
                  We specialize in custom electronic device prototyping and
                  development, tailoring designs to customer needs. If
                  specifications are unclear, we convert plain language to
                  functional specs for a seamless design process.
                </div>

                <div className="flex justify-start items-center gap-2 my-[30px]">
                  <div className="aspect-[60/60]">
                    <img
                      src={bulb}
                      alt=""
                      className="h-[60px] object-cover aspect-[60/60]"
                    />
                  </div>
                  <div className="techManagementLeftSubTitle ">
                    We’re doing the right thing. The right way.
                  </div>
                </div>

                <div>
                  <div className="flex gap-2 items-center techManagementLeftContentMargin">
                    <div>
                      <FontAwesomeIcon
                        icon={faCircleChevronRight}
                        size="lg"
                        style={{ color: "#2792D0" }}
                      />
                    </div>
                    <div className="techManagementListItem">
                      Custom-made Solution
                    </div>
                  </div>

                  <div className="flex gap-2 items-center techManagementLeftContentMargin">
                    <div>
                      <FontAwesomeIcon
                        icon={faCircleChevronRight}
                        size="lg"
                        style={{ color: "#2792D0" }}
                      />
                    </div>
                    <div className="techManagementListItem">
                      Precision in Design
                    </div>
                  </div>

                  <div className="flex gap-2 items-center techManagementLeftContentMargin">
                    <div>
                      <FontAwesomeIcon
                        icon={faCircleChevronRight}
                        size="lg"
                        style={{ color: "#2792D0" }}
                      />
                    </div>
                    <div className="techManagementListItem">
                      Customer-Centric Focus
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="z-[1] ml-0 hidden lg:block">
              <div className="bg-gradient-to-b from-[#2792D0] to-[#195CA5] flex items-end justify-center h-full w-[193px]">
                <div className="techManagementRightSubTitle max-w-[193px] w-full flex justify-center text-start p-4">
                  Professional IT technology services you can trust
                </div>
              </div>
            </div>

            <div className="flex lg:hidden pt-[40px] md:pt-[60px] order-first">
              <img src={TechManagementImage} alt="" className="object-fill" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TechManagement;
