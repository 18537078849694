import React from "react";

function ECityDetailsCard({ cardDetails }) {
  return (
    <div className="bg-[#195CA51A]/10 px-[31px] py-[47px]">
      <div>
        <div className="flex justify-center">
          <img src={cardDetails.image} alt="" className="w-[50px] h-[50px]" />
        </div>
        <div className="eCityDetailsCardTitle">{cardDetails.title}</div>
      </div>
    </div>
  );
}

export default ECityDetailsCard;
