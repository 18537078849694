import React from 'react';
import './ExcellenceCard.css';

function ExcellenceCard({ cardDetails }) {
  return (
    <div>
      <div className="relative inline-block bg-white excellenceCardShadow pt-[40px]">
        <div className="flex justify-center">
          <div className="p-[10px] bg-[#F4F5F9] inline-block excellenceCardShadow absolute top-[-40px]"><img src={cardDetails.image} alt="" className="h-[60px]" /></div>
        </div>
        <div className="flex flex-col items-center p-3">
          <div className="excellenceCardNumberText">{ cardDetails.numberText }</div>
          <div className="bg-[#2792D0] py-[8px] px-[10px] excellenceCardTitle">{cardDetails.title}</div>
        </div>

      </div>
    </div>
  );
}

export default ExcellenceCard;
