/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faChevronRight, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import leftSideBannerImage from "../../assets/Components/Home/MapSection/leftSideBannerImage.png";
import bannerImage from "../../assets/Components/Services/Rectangle 26.png";
import facebookImg from "../../assets/Components/Services/fe_facebook.png";
import linkdinImg from "../../assets/Components/Services/uil_linkedin.png";
import { serviceData } from "../../store/serviceStore";
import "../Career/Career.css";
import "./Services.css";

function Services() {
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState(1);

  // console.log("selectedService = ", selectedService);

  const { id: paramId } = useParams();
  // console.log('paramId = ', paramId);

  useEffect(() => {
    if (paramId) {
      setSelectedService(parseInt(paramId));
    }
  }, [paramId]);

  useEffect(() => {
    window.scrollTo({
      top: 400,
      behavior: "smooth",
    });
    // if (localStorage.getItem("service-id")) {
    //   setSelectedService(parseInt(localStorage.getItem("service-id")));
    // }
  }, [paramId]);
  // console.log(localStorage.getItem("service-id"));
  return (
    <>
      <Navbar />
      <div className="relative">
        <div className="career-hero">
          <div className="container flex flex-col justify-center h-full">
            <div className="flex flex-row heroTitle">Solution & Services</div>
            <div className="mt-6 flex flex-row items-center">
              <FontAwesomeIcon icon={faHouse} color="white" />
              <FontAwesomeIcon
                icon={faChevronRight}
                color="white"
                style={{ paddingLeft: "1rem" }}
              />
              <p
                className="text-white"
                style={{
                  paddingLeft: "1rem",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                Services
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-20 container">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
          <div className="col-span-1 gap-6">
            <div className="flex flex-col gap-6">
              {serviceData.map((data) =>
                selectedService === data.id ? (
                  <div
                    key={data.id}
                    className="flex flex-row cursor-pointer"
                    // onClick={() => setSelectedService(data.id)}
                    onClick={() => navigate(`/services/${data.id}`)}
                  >
                    <div className="px-5 py-4 flex items-center bg-[#2792D0]">
                      <FontAwesomeIcon icon={faChevronRight} color="white" />
                    </div>
                    <div className="px-4 py-4 nav-title bg-[#293742] w-full text-white">
                      <p>{data.title}</p>
                    </div>
                  </div>
                ) : (
                  <div
                    key={data.id}
                    className="flex flex-row cursor-pointer"
                    onClick={() => {
                      // setSelectedService(data.id);
                      navigate(`/services/${data.id}`);

                      window.scrollTo({
                        top: 400,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <div
                      className="px-3 py-4 flex items-center"
                      style={{
                        background: "rgba(39, 146, 208, 0.1)",
                        color: "rgba(133, 142, 150, 1)",
                      }}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    <div className="px-4 py-4 nav-title w-full">
                      <p>{data.title}</p>
                    </div>
                  </div>
                )
              )}
              <div className="p-4 follow-section">
                <div className="flex flex-row">
                  <p>Follow Us on</p>
                </div>
                <div className="flex flex-row justify-center items-center h-2/3 gap-6">
                  <div
                    className="p-4 rounded-full"
                    style={{ background: "rgba(44, 46, 51, 0.1)" }}
                  >
                    <a
                      href="https://www.facebook.com/people/SMARTMATRIX-PTE-LTD/100063454840485/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={facebookImg}
                        alt="facebookImg"
                        className="h-[34px]"
                      />
                    </a>
                  </div>
                  <div
                    className="p-4 rounded-full"
                    style={{ background: "rgba(44, 46, 51, 0.1)" }}
                  >
                    <a
                      href="https://www.linkedin.com/company/smartmatrix-pte-ltd/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={linkdinImg}
                        alt="facebookImg"
                        className="h-[34px]"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            {serviceData.map((data, index) =>
              selectedService === data.id ? (
                <div key={index}>
                  <img
                    src={data.image}
                    alt={data.alt}
                    className="w-full h-[400px] object-cover"
                  />
                  <p className="my-6 details-header">{data.headerTitle}</p>
                  {selectedService === 1 ? (
                    <div className="service-description">
                      <p className="pb-5">
                        Welcome to our comprehensive suite of services, where
                        innovation meets efficiency. Navigate the digital
                        landscape seamlessly with our Website, ERP, and SaaS
                        solutions tailored for your business success. Experience
                        the synergy of a powerful website, streamlined ERP
                        processes, and the flexibility of SaaS, all designed to
                        elevate your operations. From optimizing workflows to
                        enhancing collaboration, our integrated approach ensures
                        your business thrives in the digital era. Join us in
                        transforming your journey – where the convergence of
                        technology and strategy propels your enterprise towards
                        unparalleled success.
                      </p>
                      <span>Website Solution & Services</span>
                      <p className="py-5">
                        Embark on a journey of digital excellence with our
                        specialized website solutions and services. We
                        understand the pivotal role a website plays in today
                        {"'"}s business landscape, serving as the gateway to
                        your online presence. Our dedicated team crafts bespoke
                        solutions, ensuring your website not only reflects your
                        brand identity but also engages and captivates your
                        audience effectively. From responsive designs to
                        e-commerce functionality, we offer a comprehensive suite
                        of services to elevate your online footprint. Whether
                        you
                        {"'"}
                        re establishing your first website or seeking to enhance
                        an existing one, our commitment is to empower your
                        digital success through innovative, tailored solutions
                        and unparalleled service.
                      </p>
                      <span>ERP Solution & Services</span>
                      <p className="py-5">
                        We assist organizations with an end-to-end solution,
                        offering design and implementation to
                        post-implementation support. With comprehensive domain
                        knowledge of business applications, We facilitates
                        effective decision-making and enables customers to meet
                        their business requirements, with a focus on quality,
                        knowledge transfer and process simplification. We
                        supports implementation engagements for clients large
                        and small, across a variety of industries. To ensure
                        that things go as smoothly as possible, SmartMatrix has
                        brought together a seasoned and experienced staff of
                        experts to help you implement business solutions that
                        effectively support your business process, not
                        functional silos. We define a roadmap for success,
                        including a thorough analysis of potential risks, and
                        take the necessary steps to mitigate risk. We design
                        solutions that scale and support application evolution
                        well beyond the term of the consulting engagement.
                      </p>
                      <span>SaaS Solution & Services</span>
                      <p className="py-5">
                        Elevate your business to new heights with our
                        cutting-edge SaaS solutions and services. Our Software
                        as a Service offerings are designed to provide scalable,
                        flexible, and secure solutions that adapt to the
                        evolving needs of your organization. From cloud-based
                        applications to subscription-based models, we deliver
                        innovative SaaS solutions that streamline processes,
                        enhance collaboration, and drive efficiency. Our
                        services extend beyond implementation, offering
                        continuous support and updates to ensure you stay ahead
                        in the rapidly changing technological landscape. Embrace
                        the future of software delivery with our SaaS solutions,
                        where seamless integration meets unparalleled service,
                        fostering a digital environment primed for success.
                      </p>
                    </div>
                  ) : selectedService === 2 ? (
                    <div className="service-description">
                      <p className="pb-5">
                        Embark on a transformative journey in the mobile realm
                        with our bespoke iOS and Android app development
                        solutions and services. We specialize in creating
                        cutting-edge mobile applications that seamlessly
                        integrate innovation, functionality, and user
                        experience. Our expert team navigates the intricacies of
                        both iOS and Android platforms to bring your unique
                        vision to life. From conceptualization to design,
                        development, and deployment, we are committed to
                        delivering apps that not only meet but exceed your
                        expectations. Elevate your digital presence and engage
                        your audience with our comprehensive app development
                        services, where creativity meets precision for
                        unparalleled mobile success.
                      </p>
                      <span>IOS Solution & Services</span>
                      <p className="py-5">
                        Enter the realm of unparalleled iOS app development
                        excellence with SmartMatrix, where innovation converges
                        with precision. Our software company is dedicated to
                        crafting cutting-edge solutions and providing expert
                        services tailored to meet the unique needs of our
                        clients. From conceptualization to deployment, our team
                        specializes in creating bespoke iOS applications that
                        redefine user experiences. Whether you
                        {"'"}
                        re envisioning a dynamic business app or an engaging
                        consumer-facing platform, we navigate the intricacies of
                        iOS development to bring your ideas to life. Trust
                        SmartMatrix for a seamless journey in iOS app
                        development, where creativity and technical expertise
                        converge for exceptional digital solutions.
                      </p>
                      <span>Android Solution & Services</span>
                      <p className="py-5">
                        Dive into the world of unparalleled Android app
                        development with SmartMatrix, a beacon of innovation and
                        precision in software solutions. Our company is
                        committed to delivering cutting-edge Android
                        applications through a comprehensive suite of tailored
                        solutions and expert services. From conceptualization to
                        deployment, our seasoned team specializes in crafting
                        bespoke Android applications that redefine user
                        experiences. Whether it
                        {"'"}s a dynamic business app or a captivating
                        consumer-facing platform, we adeptly navigate the
                        complexities of Android development to transform your
                        vision into a reality. Choose SmartMatrix for an
                        immersive journey in Android app development, where
                        creativity and technical expertise converge to deliver
                        exceptional digital solutions.
                      </p>
                    </div>
                  ) : selectedService === 8 ? (
                    <div className="service-description">
                      <p className="pb-5">
                        As a cloud solution provider, SmartMatrix offers a wide
                        range of services designed to meet the diverse needs of
                        its clients. These services include cloud infrastructure
                        provisioning, platform-as-a-service (PaaS),
                        software-as-a-service (SaaS), cloud storage, data backup
                        and recovery, virtualization, and much more. By
                        leveraging cutting-edge technologies and industry best
                        practices, SmartMatrix empowers businesses to migrate
                        their applications, data, and workloads to the cloud,
                        providing them with greater flexibility, agility, and
                        cost efficiency.
                      </p>
                      <div>
                        <b>Elevate Your Business to New Heights</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Seamlessly scale your resources with our cloud
                          solutions for unmatched flexibility and performance.
                        </li>
                      </ul>
                      <div>
                        <b>Infinite Possibilities, One Solution</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Explore the limitless potential of our cloud offerings
                          tailored to meet your unique business needs.
                        </li>
                      </ul>
                      <div>
                        <b>Where Innovation Meets Scalability</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Unleash the power of innovation with our cloud
                          services, designed for optimal scalability and
                          performance.
                        </li>
                      </ul>
                      <div>
                        <b>Beyond Boundaries</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Break free from limitations and embrace global
                          accessibility with our cloud services driving your
                          success.
                        </li>
                      </ul>
                      <div>
                        <b>Transforming Costs into Efficiency</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Experience cost-efficiency at its best with our
                          pay-as-you-go cloud services, optimizing your digital
                          investment.
                        </li>
                      </ul>
                      <div>
                        <b>Securing Your Digital Horizon</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Trust our robust security measures as we safeguard
                          your data and applications in the SmartMatrix cloud.
                        </li>
                      </ul>
                      <div>
                        <b>Seamless Operations, Anytime, Anywhere</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Redefine your business operations with our cloud
                          solutions, ensuring accessibility from any corner of
                          the world.
                        </li>
                      </ul>
                      <div>
                        <b>Reliability Redefined</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Rely on the unmatched reliability of our cloud
                          infrastructure, minimizing downtime and maximizing
                          performance.
                        </li>
                      </ul>
                      <div>
                        <b>Agility in Action</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Foster rapid innovation with our agile cloud services,
                          propelling your business into a dynamic digital
                          future.
                        </li>
                      </ul>
                      <div>
                        <b>Your Disaster-Ready Digital Sanctuary</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Ensure business continuity with our disaster recovery
                          features, keeping your operations resilient in
                          unforeseen circumstances.
                        </li>
                      </ul>
                      <div>
                        <b>Green Computing for a Sustainable Future</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Join us in the journey towards sustainability with our
                          environmentally friendly cloud computing solutions.
                        </li>
                      </ul>
                      <div>
                        <b>Customized Cloud Excellence</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Craft your unique cloud environment with our
                          customizable solutions, aligning your cloud strategy
                          with business goals.
                        </li>
                      </ul>
                      <div>
                        <b>Your Partner in Digital Evolution</b>
                      </div>
                      <ul className="pb-4">
                        <li>
                          Experience unparalleled support and service level
                          agreements as we stand by your side in the SmartMatrix
                          cloud journey.
                        </li>
                      </ul>
                    </div>
                  ) : selectedService === 12 ? (
                    <div className="service-description">
                      <p className="pb-5">
                        SmartMatrix is at the forefront of providing
                        cutting-edge RFID/Barcode solutions that redefine
                        efficiency, accuracy, and automation in diverse
                        industries. From inventory management and supply chain
                        optimization to access control and asset tracking, our
                        RFID and Barcode solutions offer a seamless integration
                        of technology to enhance operational processes.
                      </p>
                      <span>RFID Solutions</span>
                      <p className="py-5">
                        RFID (Radio Frequency Identification) technology has
                        transformed how businesses manage and track assets.
                        SmartMatrix
                        {"'"}s RFID solutions leverage this technology for
                        efficient data capture and real-time visibility. RFID
                        tags, equipped with unique identifiers, allow for the
                        automatic identification of items without direct
                        line-of-sight.
                      </p>
                      <p className="py-5">
                        One of the key advantages of RFID is its ability to
                        enable real-time tracking of assets. Whether it ` s
                        inventory in a retail store, equipment in a healthcare
                        facility, or goods in a warehouse, RFID technology
                        provides accurate and instant visibility into the
                        location and status of items. This real-time tracking
                        capability significantly reduces manual efforts,
                        minimizes errors, and enhances overall operational
                        efficiency.
                      </p>
                      <p className="py-5">
                        RFID is particularly valuable in supply chain
                        management, where the ability to track products from
                        manufacturing to distribution is critical. By embedding
                        RFID tags in products or packaging, businesses can trace
                        the entire journey of items, optimize logistics, and
                        ensure timely deliveries
                      </p>
                      <p className="py-5">
                        Furthermore, RFID is a powerful tool for access control
                        and security. SmartMatrix designs RFID-based access
                        systems that enhance the security of facilities by
                        providing controlled and traceable entry. From employee
                        access cards to event management, our RFID solutions
                        offer a comprehensive approach to secure access.
                      </p>
                      <span>Barcode Solutions</span>
                      <p className="py-5">
                        Barcodes have been a staple in the business world for
                        decades, and SmartMatrix
                        {"'"}s Barcode solutions elevate their utility to new
                        heights. Our Barcode solutions cover a wide spectrum of
                        applications, from product labeling to ticketing and
                        asset tracking.
                      </p>
                      <p className="py-5">
                        In retail, SmartMatrix ` s Barcode solutions enhance the
                        checkout process, inventory management, and even
                        customer engagement. Barcode scanning allows for quick
                        and accurate product identification, reducing queuing
                        times and ensuring a positive customer experience.
                      </p>
                      <p>
                        Asset tracking is another area where Barcode solutions
                        excel. By labeling equipment, tools, or inventory items
                        with barcodes, businesses can easily track their
                        location, usage, and maintenance history. This not only
                        prevents loss but also optimizes asset utilization and
                        maintenance schedules.
                      </p>
                      <span>Integration of RFID and Barcode:</span>
                      <p className="py-5">
                        SmartMatrix takes a holistic approach by integrating
                        RFID and Barcode solutions for clients who require the
                        combined benefits of both technologies. This integrated
                        approach offers flexibility and adaptability, allowing
                        businesses to leverage the strengths of RFID for
                        real-time tracking and Barcodes for cost-effective and
                        straightforward identification.
                      </p>
                    </div>
                  ) : selectedService === 15 ? (
                    <div className="service-description">
                      <p className="pb-5">
                        SmartMatrix is dedicated to revolutionizing the
                        healthcare industry through innovative and comprehensive
                        Healthcare Solutions. By leveraging cutting-edge
                        technologies, our solutions aim to enhance patient care,
                        improve operational efficiency, and drive positive
                        outcomes across the healthcare ecosystem.
                      </p>
                      <div className="pb-4">
                        <b>Telemedicine and Remote Patient Monitoring:</b>
                        <p>
                          Telemedicine has become an integral part of modern
                          healthcare, providing accessible and efficient care
                          remotely. SmartMatrix ` s Telemedicine Solutions
                          facilitate virtual consultations, enabling patients to
                          connect with healthcare professionals from the comfort
                          of their homes. Additionally, our Remote Patient
                          Monitoring solutions leverage wearable devices and
                          connected sensors to track vital signs and health
                          metrics in real-time, allowing healthcare providers to
                          monitor and intervene proactively.
                        </p>
                      </div>
                      <div className="pb-4">
                        <b>
                          Electronic Health Records (EHR) and Health Information
                          Systems:
                        </b>
                        <p>
                          Our EHR solutions streamline the management of patient
                          records and health information. SmartMatrix offers
                          robust and secure platforms that allow healthcare
                          providers to digitize patient data, ensuring its
                          accessibility, accuracy, and confidentiality.
                          Integration with Health Information Systems enhances
                          interoperability and collaboration among healthcare
                          professionals, leading to more informed
                          decision-making.
                        </p>
                      </div>
                      <div className="pb-4">
                        <b>Hospital Management Systems:</b>
                        <p>
                          Efficient hospital management is critical for
                          providing quality patient care. SmartMatrix ` s
                          Hospital Management Systems automate administrative
                          tasks, including appointment scheduling, billing, and
                          inventory management. These systems optimize
                          workflows, reduce paperwork, and enhance the overall
                          efficiency of healthcare institutions.
                        </p>
                      </div>
                      <div className="pb-4">
                        <b>Medical Imaging Solutions:</b>
                        <p>
                          SmartMatrix
                          {"'"}s Medical Imaging Solutions leverage advanced
                          technologies such as Picture Archiving and
                          Communication Systems (PACS) and Radiology Information
                          Systems (RIS) to enhance the management and
                          interpretation of medical images. These solutions
                          improve diagnostic accuracy, facilitate image sharing,
                          and contribute to better-informed treatment decisions.
                        </p>
                      </div>
                      <div className="pb-4">
                        <b>Pharmacy Management Systems:</b>
                        <p>
                          In the complex landscape of healthcare, efficient
                          pharmacy management is essential. SmartMatrix provides
                          Pharmacy Management Systems that automate prescription
                          management, inventory control, and billing processes.
                          These systems enhance accuracy, reduce errors, and
                          improve the overall efficiency of pharmacy operations.
                        </p>
                      </div>
                      <div className="pb-4">
                        <b>Health Analytics and Business Intelligence:</b>
                        <p>
                          Data-driven insights are invaluable for healthcare
                          organizations. SmartMatrix
                          {"'"}s Health Analytics and Business Intelligence
                          solutions enable healthcare providers to analyze large
                          datasets, derive actionable insights, and make
                          informed decisions. These tools contribute to
                          improving patient outcomes, optimizing resource
                          allocation, and identifying trends and patterns.
                        </p>
                      </div>
                      <div className="pb-4">
                        <b>Patient Engagement and Wellness Solutions: </b>
                        <p>
                          Empowering patients to actively participate in their
                          healthcare journey is a key focus of SmartMatrix. Our
                          Patient Engagement and Wellness Solutions include
                          mobile applications and platforms that provide
                          patients with access to educational resources,
                          appointment reminders, and personalized wellness
                          plans. By fostering patient engagement, these
                          solutions contribute to better health outcomes.
                        </p>
                      </div>
                      <div className="pb-4">
                        <b>Cybersecurity Solutions for Healthcare: </b>
                        <p>
                          Recognizing the importance of data security in
                          healthcare, SmartMatrix offers robust cybersecurity
                          solutions. These measures safeguard patient
                          information, ensure compliance with data protection
                          regulations, and protect healthcare institutions from
                          cyber threats.
                        </p>
                      </div>
                      <p>
                        In conclusion, SmartMatrix
                        {"'"}s Healthcare Solutions represent a comprehensive
                        and integrated approach to addressing the evolving needs
                        of the healthcare industry. By embracing technologies
                        such as telemedicine, electronic health records, and
                        health analytics, we empower healthcare providers to
                        deliver more personalized, efficient, and secure care.
                        SmartMatrix stands as a committed partner in shaping the
                        future of healthcare, where technology plays a pivotal
                        role in improving patient outcomes and advancing the
                        overall quality of healthcare delivery. Choose
                        SmartMatrix for transformative Healthcare Solutions that
                        propel the industry into a new era of innovation and
                        patient-centric care.
                      </p>
                    </div>
                  ) : (
                    <div className="service-description">
                      {parse(data.description)}
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )
            )}
            {selectedService === 16 ? (
              <a
                type="button"
                className="mt-9 DiscoverMoreButton"
                href="https://www.smartmatrix.life/"
                target="_blank"
                rel="noreferrer"
              >
                Get Solution
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="bg-[#2792D0] flex justify-between p-0">
          <div className="hidden lg:flex">
            <img src={bannerImage} alt="" className="w-[290px] h-[176px]" />
          </div>

          <div className="flex flex-col gap-5 justify-center items-center">
            <div className="bannerTitle p-5">Need an IT Solution?</div>
          </div>

          <div className="max-w-[335px] w-full relative">
            <div className="h-full flex justify-center">
              <img
                src={leftSideBannerImage}
                alt=""
                className="h-full  aspect-[335/176] object-contain"
              />
            </div>
            <div className="flex justify-center items-center absolute h-full w-full top-0">
              <button
                type="button"
                className="bannerButtonText"
                onClick={() => navigate("/contact")}
              >
                <nobr>CONTACT US</nobr>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Services;
