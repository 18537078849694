/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import ClientsImg1 from '../../assets/Components/Ecity/OurClients/ClientsImg1.png';
import ClientsImg2 from '../../assets/Components/Ecity/OurClients/ClientsImg2.png';
import ClientsImg3 from '../../assets/Components/Ecity/OurClients/ClientsImg3.png';
import MiniReUseableTitle from '../Home/MiniReUseableTitle/MiniReUseableTitle';
import { useNavigate } from 'react-router-dom';

function OurClients() {
  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="py-[40px] md:py-[50px] lg:py-[90px]">
        <div className="grid lg:grid-cols-2 gap-2 lg:gap-5">
          <div className="order-last lg:order-first">
            <MiniReUseableTitle title="OUR CLIENTS" color="#195CA5" />

            <div className="sectionTitle aboutOurCompanyRightSectionMargin">
              Clients we've partnered with include a diverse mix of industry
              leaders and innovative startups.
            </div>

            <div className="sectionContent  aboutOurCompanyRightSectionMargin">
              Over the years, we've had the privilege of collaborating with a
              diverse range of clients, from established industry leaders to
              innovative startups. Our commitment to delivering tailored
              solutions has earned us the trust of businesses across the IT
              solution and commodity sector.
            </div>

            <div className="mt-[30px]">
              <button
                type="button"
                className="buttonPrimary"
                onClick={() => navigate('/contact')}
              >
                GET IN TOUCH
              </button>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="grid sm:grid-cols-2 gap-3 lg:gap-5 justify-between">
              <div>
                <div className="flex justify-center sm:justify-end items-end h-full">
                  <img src={ClientsImg1} alt="" className="max-h-[384px]" />
                </div>
              </div>

              <div className="grid gap-3">
                <div className="flex justify-center sm:justify-start items-end h-full">
                  <img src={ClientsImg2} alt="" className="max-h-[253px] " />
                </div>

                <div className="flex justify-center sm:justify-start items-end  lg:hidden">
                  <img src={ClientsImg3} alt="" className="max-h-[230px] " />
                </div>
              </div>

              <div className="relative col-span-2 hidden lg:block">
                <div className="absolute w-full flex justify-center">
                  <img src={ClientsImg3} alt="" className="max-h-[230px] " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurClients;
