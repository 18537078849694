/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from "react";
// import Slider from 'react-slick';
import { clientAndConcernData } from "../../../store/store";
import MiniReUseableTitle from "../MiniReUseableTitle/MiniReUseableTitle";
import "./ClientAndConcern.css";
import ClientAndConcernCard from "./ClientAndConcernCard";

function ClientAndConcern() {
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   speed: 300,
  //   autoplaySpeed: 2000,
  //   cssEase: 'linear',
  //   responsive: [
  //     {
  //       breakpoint: 1400, // 600px আগ পর্যন্ত 2টা দেখাবে
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2
  //       }
  //     },
  //     {
  //       breakpoint: 1200, // 600px আগ পর্যন্ত 2টা দেখাবে
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 4
  //       }
  //     },

  //     {
  //       breakpoint: 1000, // 600px আগ পর্যন্ত 2টা দেখাবে
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3
  //       }
  //     },

  //     {
  //       breakpoint: 800, // 600px আগ পর্যন্ত 2টা দেখাবে
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2
  //       }
  //     },
  //     {
  //       breakpoint: 600, // 600px আগ পর্যন্ত 2টা দেখাবে
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2
  //       }
  //     },
  //     {
  //       breakpoint: 400, // 299px আগ পর্যন্ত একটা দেখাবে
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };

  return (
    <div className="container sectionContentMargin1">
      <div className="flex justify-center">
        <div className="flex flex-col items-center max-w-[578px]">
          <MiniReUseableTitle title="Client & Concern" color="#195CA5" />
          <div className="sectionTitle text-center sectionMarginBetweenSectionTitleAndSectionContent">
            Trusted by 120k+ Users from 3000+ companies worldwide
          </div>
        </div>
      </div>

      {/* <div className="mt-[60px]">
        <div>
          <Slider {...settings}>
            {clientAndConcernDataColumn1.map((cardDetails, index) => (
              <div key={index}>
                <ClientAndConcernCard cardDetails={cardDetails} />
              </div>
            ))}
          </Slider>
        </div>

        <div className="mt-[20px]">
          <Slider {...settings}>
            {clientAndConcernDataColumn2.map((cardDetails, index) => (
              <div key={index}>
                <ClientAndConcernCard cardDetails={cardDetails} />
              </div>
            ))}
          </Slider>
        </div>

        <div className="mt-[20px]">
          <Slider {...settings}>
            {clientAndConcernDataColumn3.map((cardDetails, index) => (
              <div key={index}>
                <ClientAndConcernCard cardDetails={cardDetails} />
              </div>
            ))}
          </Slider>
        </div>
      </div> */}

      {/* <div
        className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-5 clientAndConcernCardContainerMargin"
        //style={{ alignItems: "center" }}
      >
        {clientAndConcernData.map((cardDetails) => (
          <ClientAndConcernCard
            cardDetails={cardDetails}
            key={cardDetails.id}
          />
        ))}
      </div> */}
      <div
        className="flex flex-wrap gap-8 clientAndConcernCardContainerMargin"
        //style={{ alignItems: "center" }}
      >
        {clientAndConcernData.map((cardDetails) => (
          <ClientAndConcernCard
            cardDetails={cardDetails}
            key={cardDetails.id}
          />
        ))}
      </div>
    </div>
  );
}

export default ClientAndConcern;
