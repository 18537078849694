import React from 'react';
import Footer from '../../Components/Footer/Footer';
import AboutOurCompany from '../../Components/Home/AboutOurCompany/AboutOurCompany';
import ClientAndConcern from '../../Components/Home/ClientAndConcern/ClientAndConcern';
import DevopsSolution from '../../Components/Home/DevopsSolution/DevopsSolution ';
import Hero from '../../Components/Home/Hero/Hero';
import MapSection from '../../Components/Home/MapSection/MapSection';
import RecentlyCompletedWork from '../../Components/Home/RecentlyCompletedWork/RecentlyCompletedWork';
import ServicesWeAreOffering from '../../Components/Home/ServicesWeAreOffering/ServicesWeAreOffering';
import TechManagement from '../../Components/Home/TechManagement/TechManagement';
import TechnologyThornWeUse from '../../Components/Home/TechnologyThornWeUse/TechnologyThornWeUse';
import WhyChooseSmartmatrix from '../../Components/Home/WhyChooseSmartmatrix/WhyChooseSmartmatrix';
import Workflow from '../../Components/Home/Workflow/Workflow';
import Navbar from '../../Components/Navbar/Navbar';
import './Home.css';

function Home() {
  return (
    <div>
      <Navbar />
      <Hero />
      <AboutOurCompany />
      <ServicesWeAreOffering />
      <TechnologyThornWeUse />
      <Workflow />
      <WhyChooseSmartmatrix />
      <ClientAndConcern />
      <TechManagement />
      <DevopsSolution />
      <RecentlyCompletedWork />
      <MapSection />
      <Footer />
    </div>
  );
}

export default Home;
