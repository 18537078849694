/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// eslint-disable-next-line import/no-extraneous-dependencies
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// eslint-disable-next-line import/no-extraneous-dependencies
import { faChevronRight, faHouse } from '@fortawesome/free-solid-svg-icons';

import React from 'react';

function AboutHeroSection() {
  return (
    <div className="relative">
      <div className="about-hero">
        <div className="container flex flex-col justify-center h-full">
          <div className="flex flex-row heroTitle">About Us</div>
          <div className="mt-6 flex flex-row items-center">
            <FontAwesomeIcon icon={faHouse} color="white" />
            <FontAwesomeIcon icon={faChevronRight} color="white" style={{ paddingLeft: '1rem' }} />
            <p className="text-white" style={{ paddingLeft: '1rem', fontSize: '18px', fontWeight: '600' }}>About Us</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutHeroSection;
