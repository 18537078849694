import { faChevronRight, faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import OurClients from '../../Components/ClientConcern/OurClients';
import Footer from '../../Components/Footer/Footer';
import Navbar from '../../Components/Navbar/Navbar';
import './ClientConcern.css';
import TrustedByGlobalBrands from './TrustedByGlobalBrands/TrustedByGlobalBrands';
import ReuseableFooterBanner from '../../Components/reuseableComponents/reuseableFooterBanner/ReuseableFooterBanner';
import bannerImage from '../../assets/Components/Ecity/OurClients/Rectangle 26.png';

function ClientConcern() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <div className="relative">
        <div className="client-hero">
          <div className="container flex flex-col justify-center h-full">
            <div className="flex flex-row heroTitle">Client & Concern</div>
            <div className="mt-6 flex flex-row items-center">
              <FontAwesomeIcon icon={faHouse} color="white" />
              <FontAwesomeIcon
                icon={faChevronRight}
                color="white"
                style={{ paddingLeft: '1rem' }}
              />
              <p
                className="text-white"
                style={{
                  paddingLeft: '1rem',
                  fontSize: '18px',
                  fontWeight: '600',
                }}
              >
                Our Clients
              </p>
            </div>
          </div>
        </div>
      </div>

      <OurClients />
      <TrustedByGlobalBrands />
      <ReuseableFooterBanner
        bannerImage={bannerImage}
        title="Need an IT Solution?"
        content="Got a project in mind? We’d love to hear about it. Take five minutes to fill out our project form so that we can get to know you and understand your project."
        buttonName="CONTACT US"
        url="/contact"
      />
      <Footer />
    </div>
  );
}

export default ClientConcern;
