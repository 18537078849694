/* eslint-disable max-len */
import React from 'react';
import './ReuseableFooterBanner.css';
import { useNavigate } from 'react-router-dom';

function ReuseableFooterBanner({
  bannerImage,
  title,
  content,
  buttonName,
  url,
}) {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <div className="container">
        <div
          className="reuseableFooterBannerContainer fadeIn animated"
          style={{ visibility: 'visible', animationName: 'fadeIn' }}
        >
          <div className="hidden lg:flex min-h-[176px] aspect-[290/176] lg:w-1/4">
            <img
              src={bannerImage}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>

          <div className="reuseableFooterBannerContentBox lg:w-3/4">
            <div className="mx-5 md:mx-0 md:ml-[50px] my-5">
              {title && (
                <div className="reuseableFooterBannercontentTitle">{title}</div>
              )}

              {content && (
                <div className="reuseableFooterBannercontent">
                  Got a project in mind? We’d love to hear about it. Take five
                  minutes to fill out our project form so that we can get to
                  know you and understand your project.
                </div>
              )}
            </div>

            <div className="flex items-center mx-[76px] md:w-auto justify-center">
              <button
                type="button"
                className="reuseableFooterBannerButtonText z-[1]"
                onClick={() => navigate(url)}
              >
                <nobr>{buttonName}</nobr>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReuseableFooterBanner;
