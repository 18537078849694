import React from 'react';
import { Link } from 'react-router-dom';
import './ServicesWeAreOfferingCard.css';

function ServicesWeAreOfferingCard({ cardDetails, url }) {
  return (
    <div
      className=""
      // onClick={() => localStorage.setItem("service-id", cardDetails.serviceId)}
    >
      <div className="border border-[#2792D0] h-full p-5 mx-auto servicesWeAreOfferingCardContainer">
        <Link to={url}>
          <div className="flex justify-center">
            <img
              src={cardDetails.imgae}
              alt={cardDetails.imgae}
              className="h-[33px]"
            />
          </div>

          <div className="flex justify-center servicesWeAreOfferingCardTitle servicesWeAreOfferingCardContentMargin">
            {cardDetails.title || 'No Title Found'}
          </div>
          <div className="flex justify-center servicesWeAreOfferingCardContent servicesWeAreOfferingCardContentMargin">
            {cardDetails.content || 'No Content Found'}
          </div>
        </Link>
      </div>
    </div>
  );
}

export default ServicesWeAreOfferingCard;
