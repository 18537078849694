import React from 'react';
import ECitySolution from '../../Components/Ecity/ECitySolution/ECitySolution';
import Hero from '../../Components/Ecity/Hero/Hero';
import ServicesForEcity from '../../Components/Ecity/ServicesForEcity/ServicesForEcity';
import Footer from '../../Components/Footer/Footer';
import Navbar from '../../Components/Navbar/Navbar';
import ReuseableFooterBanner from '../../Components/reuseableComponents/reuseableFooterBanner/ReuseableFooterBanner';
import bannerImage from '../../assets/Components/Ecity/FooterBanner/leftSideBannerImage.png';

function Ecity() {
  return (
    <div>
      <Navbar />
      <Hero />
      <ECitySolution />
      <ServicesForEcity />

      <div className="mt-[45px] lg:mt-[90px]">
        <ReuseableFooterBanner
          bannerImage={bannerImage}
          title="Starting with SmartMatrix is easy, fast and free"
          buttonName="GET STARTED"
          url="/contact"
        />
      </div>
      <Footer />
    </div>
  );
}

export default Ecity;
