/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React from "react";
import Slider from "react-slick";
import {
  servicesForEcityCardDataColumn1,
  servicesForEcityCardDataColumn2,
} from "../../../store/eCityStore";
import ServicesWeAreOfferingCard from "../../Home/ServicesWeAreOffering/ServicesWeAreOfferingCard";
import "./ServicesForEcity.css";

function ServicesForEcity() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 2000,
    cssEase: "linear",
    prevArrow: false, // Hide the previous button
    nextArrow: false,
    responsive: [
      {
        breakpoint: 1200, // 600px আগ পর্যন্ত 2টা দেখাবে
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992, // 600px আগ পর্যন্ত 2টা দেখাবে
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768, // 299px আগ পর্যন্ত একটা দেখাবে
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="servicesForEcityBackground py-[60px] relative overflow-hidden">
      <div className="container">
        <div className="flex justify-center">
          <div className="flex flex-col items-center justify-center max-w-[812px]">
            <div className="flex items-center">
              <div className="servicesWeAreOfferingText">
                SERVICES for E-CITY
              </div>
              <div className="bg-[#D4D4D4] w-[35px] h-[1.5px] ml-[10px]" />
            </div>
            <div className="servicesWeAreOfferingTitle text-center mt-[20px]">
              Empowering Citizens, Authorities, and National Services for
              Environmental Protection and Safety
            </div>
          </div>
        </div>

        <div className="mt-[60px]">
          <div>
            <Slider {...settings}>
              {servicesForEcityCardDataColumn1.map((cardDetails, index) => (
                <div key={index}>
                  <ServicesWeAreOfferingCard
                    cardDetails={cardDetails}
                    url={`/ecitydetails/${cardDetails.id}`}
                  />
                </div>
              ))}
            </Slider>
          </div>

          <div className="mt-[20px]">
            <Slider {...settings}>
              {servicesForEcityCardDataColumn2.map((cardDetails, index) => (
                <div key={index} className="">
                  <ServicesWeAreOfferingCard
                    cardDetails={cardDetails}
                    url={`/ecitydetails/${cardDetails.id}`}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesForEcity;
