/* eslint-disable import/prefer-default-export */
import hourImg from '../assets/Components/Career/work 1 (1).png';
import medImg from '../assets/Components/Career/work 1 (2).png';
import shareImg from '../assets/Components/Career/work 1 (3).png';
import growthImg from '../assets/Components/Career/work 1 (4).png';
import cultureImg from '../assets/Components/Career/work 1 (5).png';
import meetingImg from '../assets/Components/Career/work 1 (6).png';
import lunchImg from '../assets/Components/Career/work 1 (7).png';
import gameImg from '../assets/Components/Career/work 1 (8).png';
import workImg from '../assets/Components/Career/work 1.png';

export const careerCardData = [
  {
    id: 1,
    title: 'Great Work Environment',
    image: workImg,
    alt: 'workImg',
    description: 'Empowering success in a collaborative, diverse, and wellness-focused environment, where growth, recognition, and transparent communication define our culture.'
  },
  {
    id: 2,
    title: 'Flexible Hours',
    image: hourImg,
    alt: 'hourImg',
    description: 'Enjoy work-life balance with flexible hours, a benefit that empowers employees to optimize productivity while accommodating personal needs and commitments.'
  },
  {
    id: 3,
    title: 'Medical Insurance',
    image: medImg,
    alt: 'medImg',
    description: 'Secure your well-being with comprehensive medical insurance, a valued benefit ensuring peace of mind and access to quality healthcare for our dedicated team.'
  },
  {
    id: 4,
    title: 'Knowledge Share',
    image: shareImg,
    alt: 'shareImg',
    description: 'Unlock collective potential through knowledge sharing. Join a dynamic culture where insights flow freely, fostering continuous learning and innovation.'
  },
  {
    id: 5,
    title: 'Career Growth',
    image: growthImg,
    alt: 'growthImg',
    description: 'Foster your career journey with robust growth opportunities. Join us to unleash your potential, explore diverse paths, and achieve professional excellence.'
  },
  {
    id: 6,
    title: 'Great Culture',
    image: cultureImg,
    alt: 'cultureImg',
    description: 'Thrive in a vibrant culture that values collaboration, innovation, and employee well-being. Join us for a fulfilling career and a positive work environment.'
  },
  {
    id: 7,
    title: 'Meeting Room',
    image: meetingImg,
    alt: 'meetingImg',
    description: 'Empower collaboration in our well-equipped meeting rooms. Elevate productivity and innovation in a conducive space. Join us for seamless teamwork and success!'
  },
  {
    id: 8,
    title: 'Lunch, Snacks & Coffee',
    image: lunchImg,
    alt: 'lunchImg',
    description: 'Savor perks with complimentary lunch, snacks, and coffee, enhancing your workday experience. Join us for a delectable blend of productivity and indulgence!'
  },
  {
    id: 9,
    title: 'Game Zone',
    image: gameImg,
    alt: 'gameImg',
    description: 'Elevate work-life balance with our game zone—relax, recharge, and foster team spirit in a dynamic, enjoyable workplace. Join us for a playful, productive journey!'
  }
]
