/* eslint-disable max-len */
import React from 'react';
import { devopsSolutionData } from '../../../store/store';
import MiniReUseableTitle from '../MiniReUseableTitle/MiniReUseableTitle';
import './DevopsSolution.css';
import DevopsSolutionCard from './DevopsSolutionCard';

function DevopsSolution() {
  return (
    <div className="relative">
      <div className="absolute bg-[#F4F4F4] h-full w-[65vw] top-0 right-0 z-[-1]" />
      <div className="container devopsSolutionContainer">
        <div>
          <MiniReUseableTitle title="DEVOPS SOLUTION" color="#195CA5" />
        </div>

        <div className="grid grid-flow-row lg:grid-cols-2 gap-5 mt-[20px]">
          <div className="sectionTitle">Algoworks Approach Of DevOps Strategizing</div>
          <div className="sectionContent">Transforming the world intelligently with DevOps solutions. Innovate seamlessly, integrate efficiently, and excel in both development and operations for a smarter, connected future.</div>
        </div>

        <div className="grid grid-flow-row lg:grid-cols-2 gap-5 md:p-[60px]">

          {devopsSolutionData.map((cardDetails) => <DevopsSolutionCard cardDetails={cardDetails} key={cardDetails.id} />)}
        </div>

      </div>
    </div>
  );
}

export default DevopsSolution;
