/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import logo from '../../../assets/Navbar/logo.png';
import Hamburger from '../Navbar-Mini-Components/hamburger/Hamburger';
import DropdownNavbar from './DropdownNavbar/DropdownNavbar';
import { Link } from 'react-router-dom';

function NavbarBelowlgScreen() {
  const [showDropdownNavbarMenu, setShowDropdownNavbarMenu] = useState(false);
  return (
    <div className="px-[30px] py-[25px] block lg:hidden">
      <div className="flex justify-between items-center">
        <div>
          <Link to="/">
            <img src={logo} alt="" className="w-[140] h-[48px]" />
          </Link>
        </div>
        <Hamburger
          showDropdownNavbarMenu={showDropdownNavbarMenu}
          setShowDropdownNavbarMenu={setShowDropdownNavbarMenu}
        />
      </div>

      {showDropdownNavbarMenu && <DropdownNavbar  setShowDropdownNavbarMenu={setShowDropdownNavbarMenu}/>}
    </div>
  );
}

export default NavbarBelowlgScreen;
