/* eslint-disable max-len */
import React from 'react';
import './TechnologyThornWeUseButton.css';

function TechnologyThornWeUseButton({ buttonDetails, selectedTechnologyThornWeUseButton, setSelectedTechnologyThornWeUseButton }) {
  return (
    <button type="button" className={`${(selectedTechnologyThornWeUseButton === buttonDetails.title) ? 'technologyThornWeUseButtonActive' : 'technologyThornWeUseButtonInactive '}`} onClick={() => setSelectedTechnologyThornWeUseButton(buttonDetails.title)}>
      {buttonDetails.title}
    </button>
  );
}

export default TechnologyThornWeUseButton;
